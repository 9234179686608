import { AuthService } from './AuthService';
import { graphBaseEndpoint, graphAuthRequest } from '../Configs/AuthConfigs';
import { AppInsightsLogger } from '../Utilities/AppInsightsLogger';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { AccessTokenError } from '../DataModels/Errors/AccessTokenError';

export class GraphService {

    private authService!: AuthService;
    private logger!: AppInsightsLogger;

    constructor(authService: AuthService, logger: AppInsightsLogger) {
        this.authService = authService;
        this.logger = logger;
    }

    public async fetchUserProfile() {
        let accessToken = await this.authService.RequestAccessToken(graphAuthRequest);
        if (!accessToken) {
            throw new AccessTokenError();
        }

        this.logger.trackEvent(`GraphService - Fetching user's profile.`);

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);

        const options = {
            method: "GET",
            headers: headers
        };

        return fetch(`${graphBaseEndpoint}/me`, options)
            .then(response => response.json())
            .catch((error) => {
                this.logger.trackException(error, SeverityLevel.Critical);
            });
    }

    public async fetchUserImage(): Promise<string> {
        let accessToken = await this.authService.RequestAccessToken(graphAuthRequest);
        if (!accessToken) {
            throw new AccessTokenError();
        }

        this.logger.trackEvent(`GraphService - Fetching user's profile image.`);

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);

        const options = {
            method: "GET",
            headers: headers
        };

        let response = await fetch(`${graphBaseEndpoint}/me/photo/$value`, options);
        if (response.ok) {
            let blob = await response.blob();
            const url = window.URL || window.webkitURL;
            const blobUrl = url.createObjectURL(blob);
            return blobUrl;
        }

        throw new Error('Failed to fetch user\'s profile photo.');
    }
}