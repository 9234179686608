export class OrganizationTypes {
    public static readonly names = [
        "ThirdPartyLogistics",
        "ThirdPartyPartner",
        "CloudManufacturer",
        "Commercial",
        "DeliverHardware",
        "Design",
        "DistributionCenter",
        "Federal",
        "Manufacturer",
        "Plan",
        "Retail",
        "ReturnRepair",
        "Source",
    ]

    public static readonly displayNames: {[orgTypeName: string]: string} = {
        ThirdPartyLogistics: "Thrid Party Logistics",
        ThirdPartyPartner: "Third Party Partner",
        CloudManufacturer: "Cloud Manufacturer",
        Commercial: "Commercial",
        DeliverHardware: "Deliver Hardware",
        Design: "Design",
        DistributionCenter: "Distribution Center",
        Federal: "Federal",
        Manufacturer: "Manufacturer",
        Plan: "Plan",
        Retail: "Retail",
        ReturnRepair: "Return and Repair",
        Source: "Source"
    }
}