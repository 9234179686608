import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react';
import React, { createRef, FormEvent, useState } from 'react';
import { LocationConstants } from '../../Constants/Locations';
import { OrganizationTypes } from '../../Constants/OrganizationTypes';
import { Organization } from '../../DataModels/Organization';

interface IOrganizationTypeDropdownProps {
    id: string;
    selectedKeys: string[];
    onSelectOrgType: (event: FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => void;
    showErrorMessage: boolean;
}

export function OrganizationTypeDropdown(props: IOrganizationTypeDropdownProps) {

    const organizationOptions: IDropdownOption<string>[] = OrganizationTypes.names.map(orgTypeName => ({key: orgTypeName, text: OrganizationTypes.displayNames[orgTypeName]}));
    
    return (
        <>
            <Dropdown
                id={props.id}
                data-testid="orgRoleType_Dropdown"
                label="Type(s) of organization"
                onChange={props.onSelectOrgType}
                selectedKeys={props.selectedKeys}
                options={organizationOptions}
                required={true}
                placeholder="Select one or more organization type(s)"
                multiSelect
                errorMessage={props.showErrorMessage && props.selectedKeys.length == 0? 'Organization role type is required.' : undefined}
            />
            <br></br>
        </>
    );
}