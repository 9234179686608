import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { IExceptionTelemetry, IMetricTelemetry, SeverityLevel } from '@microsoft/applicationinsights-common';
import { IAppInsightsLogger } from './IAppInsightsLogger';

export class AppInsightsLogger implements IAppInsightsLogger {

  private appInsightsInstance: ApplicationInsights;
  private _componentId!: string;

  /**
   * Create an AppInsightsLogger instance
   * @param instrumentationKey AppInsights instrumentation key (iKey)
   * @param componentId ID or Name of a web component - strongly encouraged, though optional
   */
  constructor(instrumentationKey: string | undefined) 
  {
    if (instrumentationKey) {
      this.appInsightsInstance = AppInsightsLogger.initializeLogger(instrumentationKey);
    } else {
      throw new Error("Failed to initialize App Insights logger.");
    }

    this._componentId = "SCRBAC Admin Portal";
  }

  private static initializeLogger(appInsightKey: string): ApplicationInsights {
    const appInsightsInstance = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightKey,
      } 
    });
    appInsightsInstance.loadAppInsights();

    return appInsightsInstance;
  }

  set componentId(name: string) {
    this._componentId = name.trim();
  }

  /**
   * Safely prepends ComponentId to log message if it doesn't already contain it
   * @param message Log message
   * @returns Log message with componentId prepended
   */
  private tryPrependComponentId(message: string) {
    if (this._componentId != null && this._componentId != '' && message.indexOf(this._componentId) != 0) {
      return this._componentId + ' - ' + message.trim();
    }
    return message;
  }

  public trackTrace(traceMessage: string): void {
    this.appInsightsInstance.trackTrace({message : this.tryPrependComponentId(traceMessage)});
  }

  /**
   * Logs an Exception to Application Insights
   * @param exception Exception message or object
   * @param severity Severity Level of the exception, defaults to 2 - Warning by default
   */
  public trackException(exception: string | Error, severity?: SeverityLevel): void {
    if (typeof exception == "string") {
      this.appInsightsInstance.trackException({exception : new Error(this.tryPrependComponentId(exception))});
    } else {
      exception.message = this.tryPrependComponentId(exception.message);

      const exceptionTelemetry: IExceptionTelemetry = {
        exception: exception,
      }

      if (severity != null &&
        severity >= SeverityLevel.Information &&
        severity <= SeverityLevel.Critical) {
        exceptionTelemetry.severityLevel = severity;
      } else {
        exceptionTelemetry.severityLevel = SeverityLevel.Warning;
      }

      this.appInsightsInstance.trackException(exceptionTelemetry);
    }
  }

  public trackEvent(eventName: string, customProperties?: any): void {
    this.appInsightsInstance.trackEvent({name : this.tryPrependComponentId(eventName)}, customProperties);
  }

  /**
   * Track an aggregated metric (averages, cumulative, etc.)
   * @param value Metric value
   * @param name Name of metric
   */
  public trackMetric(value: number, name: string): void {
    const customMetric: IMetricTelemetry = {
      name: `${this._componentId} - ${name}`,
      average: value
    }
    this.appInsightsInstance.trackMetric(customMetric);
  }

  /**
 * Manually trigger an immediate send of all telemetry still in the buffer.
 */
  flush() {
    this.appInsightsInstance.flush();
  }
}