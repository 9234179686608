import { CoherenceNav, ICoherenceNavStyleProps, ICoherenceNavStyles, INavLink, INavLinkGroup } from '@coherence-design-system/controls';
import { IStyleFunctionOrObject } from '@fluentui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export interface NavigationBarProps {
    onNavCollapsed: (isCollapsed: boolean) => void;
    // onNavItemClicked: () => void;
}

export function NavigationBar(props: NavigationBarProps) {
        let navigate = useNavigate();
        const navLinkGroups: INavLinkGroup[] = [
            {
                key: 'AdminPortalNavGroup',
                links: [
                    {
                        name: 'Access Requests',
                        icon: 'CheckboxComposite',
                        key: 'AccessRequestsHome',
                        ariaLabel: 'Access Requests Page',
                        links: [
                            {
                                name: 'Pending Review',
                                key: 'pending-requests',
                                ariaLabel: 'Access Requests Pending Review Page',
                                onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                                    navigate('/requests/pending');
                                },
                                isSelected: window.location.href.startsWith(window.location.protocol + '//' + window.location.host + '/requests/pending')
                            }
                        ]
                    },
                    {
                        name: 'User Management',
                        key: 'UserManagementHome',
                        icon: 'People',
                        ariaLabel: 'User Management Page',
                        links: [
                            {
                                name: 'User Role Assignments',
                                key: 'user-role-assignments',
                                ariaLabel: 'User Management user role assignment page',
                                onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                                    navigate('users');
                                },
                                isSelected: window.location.href.startsWith(window.location.protocol + '//' + window.location.host + '/users')
                            },
                            {
                                name: 'Onboard User',
                                key: 'onboard-user',
                                ariaLabel: 'User Management onboard user',
                                onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                                    navigate('users/onboard');
                                },
                                isSelected: window.location.href.startsWith(window.location.protocol + '//' + window.location.host + '/users/onboard')
                            }
                        ]
                    },
                    {
                        name: 'Organizations',
                        key: 'OrgManagementHome',
                        icon: 'Org',
                        ariaLabel: 'Organization Management Page',
                        links: [
                            {
                                name: 'Manage Organizations',
                                key: 'manage-organization-page',
                                ariaLabel: 'Organization Management Default Page',
                                onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                                    navigate('/organizations');
                                },
                                isSelected: window.location.href == (window.location.protocol + '//' + window.location.host + '/organizations')
                            }
                        ]
                    }
                ]
            }
        ];

        return (
            <CoherenceNav
                    appName={'Microsoft Supply Chain Admin Portal'}
                    groups={navLinkGroups}
                    onNavCollapsed={props.onNavCollapsed}
                    toggleNavAriaLabel={'Admin Portal Navigation'}
                    toggleNavTooltip={{ expand: 'Expand navigation', collapse: 'Collapse navigation' }}
            />
        );
}