import React, { Fragment, useEffect, useMemo, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Link, Outlet } from 'react-router-dom';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated, useMsalAuthentication } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "./Configs/AuthConfigs";

import { AuthService } from './Services/AuthService';
import { GraphService } from './Services/GraphService';

import { AdminPortalHeader } from './AdminPortalHeader';
import { NavigationBar } from './NavigationBar';
import { PendingRequestsPage } from './Pages/AccessRequests/PendingRequestsPage';
import { ApprovedRequestsPage } from './Pages/AccessRequests/ApprovedRequestsPage';
import { RejectedRequestsPage } from './Pages/AccessRequests/RejectedRequestsPage';
import { OrgManagementHomePage } from './Pages/OrgManagement/OrgManagementHome';
import { UserManagementHomePage } from './Pages/UserManagement/UserManagementHomePage';

import { CoherenceCustomizations, CoherenceTheme } from "@coherence-design-system/styles";
import { ThemeProvider, loadTheme } from "@fluentui/react";

import { _Styles } from './Pages/Styles';
import { IUserProfile } from './DataModels/IUserProfile';
import { IAMService } from './Services/IAMService';
import { siteName } from './Constants/SiteConstants';
import { NotificationListItem } from '@coherence-design-system/controls';
import { AdminPortalFooter } from './AdminPortalFooter';
import { AppInsightsLogger } from './Utilities/AppInsightsLogger';
import { appInsightsKey } from './Configs/AppInsightsConfigs';
import { OrgManagementAppOwnerPage } from './Pages/OrgManagement/OrgManagementAppOwnerPage';
import { CreateUserProfilePage } from './Pages/UserManagement/CreateUserProfilePage';

var isFetchUserProfileActive = false;
var isFetchUserProfilePhotoActive = false;

export default function App() {

    const msalContext = useMsal();
    const isSignedIn = useIsAuthenticated();
    const appInsightsLogger = new AppInsightsLogger(appInsightsKey);
    const authService = new AuthService(msalContext, appInsightsLogger);
    const graphService = new GraphService(authService, appInsightsLogger);
    const iamService = new IAMService(authService, appInsightsLogger);

    const [isNavCollapsed, setNavStatus] = useState(false);
    const [userProfile, setUserProfile] = useState<IUserProfile>();
    const [userProfilePhotoLink, setUserProfilePhotoLink] = useState<string>();
    const [notificationItems, setNotificationItems] = useState<NotificationListItem[]>([]);

    useEffect(() => {
      // Load coherence theme
      loadTheme(CoherenceTheme);
      document.title = siteName;

      if (isSignedIn && !userProfile && !isFetchUserProfileActive) {
        isFetchUserProfileActive = true;
        graphService.fetchUserProfile().then((userProfile) => {
          isFetchUserProfileActive = false;
          setUserProfile(userProfile);
        });
      }

      if (isSignedIn && !userProfilePhotoLink && !isFetchUserProfilePhotoActive) {
        isFetchUserProfilePhotoActive = true;
        graphService.fetchUserImage().then((userProfilePhotoLink) => {
          isFetchUserProfilePhotoActive = false;
          setUserProfilePhotoLink(userProfilePhotoLink);
        });
      }
    });

    const _onNavCollapsed = (isCollapsed: boolean) => {
      setNavStatus(isCollapsed);
    }
  
    const _handleSignIn = () => {
      if (msalContext.inProgress == InteractionStatus.None) {
        msalContext.instance.loginRedirect(loginRequest)
        .catch(e => {
          console.error(e);
        });
      }
    }

    const _handleSignOut = () => {
      msalContext.instance.logout();
    }

    const _addRequestAccessNotification = (newNotification: NotificationListItem) => {
      const newNotificationList = [...notificationItems];
      newNotificationList.push(newNotification);
      setNotificationItems(newNotificationList);
    }

    return (
      <ThemeProvider {...CoherenceCustomizations}>
        <AuthenticatedTemplate>
          <AdminPortalHeader userProfile={userProfile} userProfilePhotoLink={userProfilePhotoLink} logoutAction={_handleSignOut} notificationItems={notificationItems}></AdminPortalHeader>
          <BrowserRouter>
            <NavigationBar onNavCollapsed={_onNavCollapsed}></NavigationBar>
            <main id='main' role={"main"} aria-label="Main Content Area" tabIndex={-1} className={isNavCollapsed ? _Styles.mainCollapsed : _Styles.mainExpand}>
              <div>
                <Routes>
                  <Route path="/" element={<PendingRequestsPage iamService={iamService} addNotificationAction={_addRequestAccessNotification} logger={appInsightsLogger} />}/>
                  <Route path="requests/pending" element={<PendingRequestsPage iamService={iamService} addNotificationAction={_addRequestAccessNotification} logger={appInsightsLogger} />}/>
                  <Route path="requests/approved" element={<ApprovedRequestsPage />} />
                  <Route path="requests/rejected" element={<RejectedRequestsPage />} />
                  {/* Disabled this for now. <Route path="organizations/superadmin" element={<OrgManagementHomePage iamService={iamService} />} /> */}
                  <Route path="organizations" element={<OrgManagementAppOwnerPage iamService={iamService} />} />
                  <Route path="users" element={<UserManagementHomePage iamService={iamService} />} />
                  <Route path="users/onboard" element={<CreateUserProfilePage iamService={iamService} logger={appInsightsLogger}/>} />
                </Routes>
              </div>
            </main>
            <Outlet />
          </BrowserRouter>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          {
            !isSignedIn && _handleSignIn
          }
        </UnauthenticatedTemplate>
      </ThemeProvider>
    )
}