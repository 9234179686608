require('dotenv').config({ path: `./Environments/.env.${process.env.NODE_ENV}` })

export const msalConfig = {
    auth: {
      clientId: "42db45f3-07a1-441e-a656-34e7fcfc85f5",
      authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47/",
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};
  
// This is for the user to log in to the site and access IAM Service APIs.
export const loginRequest = {
    scopes: ["api://42db45f3-07a1-441e-a656-34e7fcfc85f5/AdminAccessOnly"]
};

// This graph scope is for the user to fetch their basic profile which will be displayed on the side panel.
export const graphAuthRequest = {
  scopes: ["User.Read"]
};

// The base URLs of the backend services.
export const backendEndpoint = process.env.REACT_APP_IAM_ENDPOINT;
export const graphBaseEndpoint = "https://graph.microsoft.com/v1.0";
