import React, { useState } from 'react';

export function AdminPortalFooter() {
    return (
    <>
        <div data-testid ="footer" className='footer'>
            <p style={{marginBottom: 0}}>&copy; Microsoft { new Date().getFullYear() }</p>
             <p style={{ marginTop: 5 }}><small><a href="https://go.microsoft.com/fwlink/?LinkId=521839">Microsoft Privacy Statement</a></small></p>
        </div>
    </>);
}