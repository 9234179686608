import { CoherenceModal } from '@coherence-design-system/controls';
import { DefaultButton, IComboBox, IComboBoxOption, IDropdownOption, IStackStyles, Label, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import React, { FormEvent, useState } from 'react';
import { LocationConstants } from '../../Constants/Locations';
import { unknownOrgOnboardingError } from '../../Constants/SiteConstants';
import { GenericResponseError } from '../../DataModels/Errors/GenericResponseError';
import { Organization } from '../../DataModels/Organization';
import { OrganizationOnboardingRequest } from '../../DataModels/OrganizationOnboardingRequest';
import { IOrganizationOnboardingResult } from '../../DataModels/OrganizationOnboardingResult';
import { IAMService } from '../../Services/IAMService';
import { CountryDropdown } from '../Commons/CountryDropdown';
import { OrganizationTypeDropdown } from '../Commons/OrganizationTypeDropdown';

const tokens = {
    stackTokens: {
        childrenGap: 20,
        maxHeight: 100,
    },
    spinnerStack: {
        childrenGap: 20,
    },
};
const stackStyles: Partial <IStackStyles> = {
    root: {
        justifyContent: 'left',
        alignItems: 'left',
    }
};

const emailInputBoxStyle = { minWidth: 250 };
const buttonStackStyles: Partial<IStackStyles> = { root: { paddingBottom: 10, } };

interface IOrganizationOnboardModalProps {
    onDismiss: () => void;
    domainSearchInput: string;
    iamService: IAMService;
}

export function OrganizationOnboardModal(props: IOrganizationOnboardModalProps) {

    const [domainSearchResult, setDomainSearchResult] = useState<string>(props.domainSearchInput);

    const [domainInSearch, setDomainInSearch] = useState(false);
    const [searchBoxInput, setSearchBoxInput] = useState<string>("");
    const [showSuccessScreen, setShowSuccessScreen] = useState(false);
    const [showOnboardFailedScreen, setShowOnboardFailedScreen] = useState(false);
    const [submittingOnboardRequest, setSubmittingOnboardRequest] = useState(false);

    const [orgDisplayNameErrorMsg, setOrgDisplayNameErrorMsg] = useState('');
    const [showCountryDropdownErrorMsg, setShowCountryDropdownErrorMsg] = useState(false);
    const [showOrgRoleTypeDropdownErrorMsg, setOrgRoleTypeDropdownErrorMsg] = useState(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

    const [onboardedOrganizationId, setOnboardedOrganizationId] = useState<string>("");
    const [orgOnboardingError, setOrgOnboardingError] = useState<GenericResponseError>();

    var organizationProfile: OrganizationOnboardingRequest = {
        domainName: domainSearchResult,
        country: '',
        state: '',
        city: '',
        roleTypes: [],
        organizationName: '',
    }

    const [orgProfileDto, setOrgProfileDto] = useState(organizationProfile);

    const _onVerifyDomainClicked = async () => {
        setDomainInSearch(true);
        setDomainInSearch(false);
        setDomainSearchResult("");
    };

    const _onSearchTextChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearchBoxInput(e.currentTarget.value);
    }

    const _validateAllInput = (orgProfile: OrganizationOnboardingRequest) => {
        if (orgProfile.organizationName.length > 0
            && orgProfile.country.length > 0
            && orgProfile.roleTypes.length > 0) {
                setSubmitButtonDisabled(false);
            }
            else {
                setSubmitButtonDisabled(true);
            }
    }

    const _onChangeRoleSelections = (event: FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {
        var updatedOrgProfile: OrganizationOnboardingRequest = {...orgProfileDto};
        updatedOrgProfile.roleTypes = option?.selected ? [...updatedOrgProfile.roleTypes, option.key as string] : updatedOrgProfile.roleTypes.filter(key => key !== option?.key);
        setOrgProfileDto(updatedOrgProfile);
        _validateAllInput(updatedOrgProfile);

        if (updatedOrgProfile.roleTypes.length == 0) {
            setOrgRoleTypeDropdownErrorMsg(true);
        } else {
            setOrgRoleTypeDropdownErrorMsg(false);
        }
    };

    const _onSelectCountry = (event: FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) => {
        var updatedOrgProfile: OrganizationOnboardingRequest = {...orgProfileDto, country: option?.key as string ?? ''};
        setOrgProfileDto(updatedOrgProfile);
        _validateAllInput(updatedOrgProfile);

        if (updatedOrgProfile.country == '') {
            setShowCountryDropdownErrorMsg(true);
        } else {
            setShowCountryDropdownErrorMsg(false);
        }
    }

    const _onUpdateOrgDisplayName = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void => {
        var updatedOrgProfile: OrganizationOnboardingRequest = {...orgProfileDto, organizationName: newValue ?? ''};
        setOrgProfileDto(updatedOrgProfile);
        _validateAllInput(updatedOrgProfile);

        if (updatedOrgProfile.organizationName == '') {
            setOrgDisplayNameErrorMsg('Organization display name is required.');
        } else {
            setOrgDisplayNameErrorMsg('');
        }
    }

    const _onUpdateOrgCityName = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void => {
        var updatedOrgProfile: OrganizationOnboardingRequest = {...orgProfileDto, city: newValue ?? ''};
        setOrgProfileDto(updatedOrgProfile);
        _validateAllInput(updatedOrgProfile);
    }

    const _onUpdateOrgProvinceName = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void => {
        var updatedOrgProfile: OrganizationOnboardingRequest = {...orgProfileDto, state: newValue ?? ''};
        setOrgProfileDto(updatedOrgProfile);
        _validateAllInput(updatedOrgProfile);
    }

    const _onSubmitOnboardingForm = () => {
        setSubmittingOnboardRequest(true);
        
        props.iamService.onboardOrganization(orgProfileDto)
            .then(result => {
                _onOrganizationOnboarded(result);
            })
            .catch(error => {
                let errorObj = error as GenericResponseError;
                setOrgOnboardingError(errorObj);
                setShowOnboardFailedScreen(true);
            });
    }

    const _onOrganizationOnboarded = (result: IOrganizationOnboardingResult) => {
        setSubmittingOnboardRequest(false);
        setOnboardedOrganizationId(result.value);
        setShowSuccessScreen(true);
    }

    const _onResetClicked = () => {
        setOrgProfileDto({
            domainName: domainSearchResult,
            country: '',
            state: '',
            city: '',
            roleTypes: [],
            organizationName: '',
        });
        setShowCountryDropdownErrorMsg(false);
        setOrgRoleTypeDropdownErrorMsg(false);
    }

    function getTitle(): string {
        if (showOnboardFailedScreen) {
            return `Organization Onboarding Failed`;
        }

        if (showSuccessScreen) {
            return `Organization Onboarding Completed`;
        }

        return `Add New Organization`;
    }

    function getSubtitle(): string | undefined {
        if (showOnboardFailedScreen) {
            return `Error creating organization profile.`;
        }

        if (showSuccessScreen) {
            return `The organization has been onboarded successfully.`;
        }

        return `To add new organization, please verify its email domain first.`;
    }

    return (
        <>
            <CoherenceModal
                isOpen={true}
                onDismiss={props.onDismiss}
                modalWidth={'small'}
                height={'responsive'}
                title={getTitle()}
                subtitle={getSubtitle()}
                includeStickyAboveStyles={true}
                onRenderFooter={() => {
                    return (
                        <>
                        {
                            !showSuccessScreen &&
                            !showOnboardFailedScreen &&
                            <>
                                {
                                    domainSearchResult &&
                                        <Stack horizontal tokens={tokens.stackTokens} verticalAlign="center" styles={buttonStackStyles}>
                                            <PrimaryButton
                                                id={'onboardOrganization_SubmitButton'}
                                                data-testid="btnOnboardOrgSubmit"
                                                text={'Submit'}
                                                onClick={_onSubmitOnboardingForm}
                                                disabled={submitButtonDisabled}
                                            />
                                            <DefaultButton
                                                id={'onboardOrganization_ResetButton'}
                                                data-testid="btnOnboardOrgReset"
                                                text={'Reset'}
                                                onClick={_onResetClicked}
                                            />
                                            {
                                                submittingOnboardRequest &&
                                                    <Spinner size={SpinnerSize.medium} labelPosition='right'/>
                                            }
                                        </Stack>
                                }
                                {
                                    !domainSearchResult &&
                                        <Stack horizontal tokens={tokens.stackTokens} verticalAlign="center" styles={buttonStackStyles}>
                                            <DefaultButton text={'Cancel'} onClick={props.onDismiss} />
                                        </Stack>
                                }
                            </>
                        }
                        {
                            (showSuccessScreen ||
                            showOnboardFailedScreen) &&
                                <DefaultButton text={'Close'} onClick={props.onDismiss} />
                        }
                        </>
                    )
                }}
            >
                {
                    !showSuccessScreen &&
                    !showOnboardFailedScreen &&
                    <Stack styles={stackStyles} tokens={{childrenGap: 20}}>
                        <Stack horizontal wrap tokens={tokens.stackTokens} verticalAlign='end' styles={{...stackStyles, root: {paddingLeft: 10}}}>
                            <TextField 
                                style={emailInputBoxStyle} 
                                label="Enter the email domain to verify" 
                                data-testid="textDomainSearch" 
                                placeholder="contoso.com"
                                readOnly
                                onChange={(e) => _onSearchTextChange(e)}
                                defaultValue={domainSearchResult}
                            />
                            <Stack horizontal tokens={tokens.stackTokens} verticalAlign='center' style={{marginBottom: 0}}>
                                <PrimaryButton
                                    text='Verify Domain'
                                    data-testid="btnVerifyDomain"
                                    onClick={_onVerifyDomainClicked}
                                    disabled={searchBoxInput.length == 0}
                                />
                                {
                                    domainInSearch &&
                                        <Spinner size={SpinnerSize.medium} labelPosition='right'/>
                                }
                            </Stack>
                        </Stack>
                        {
                            !domainInSearch &&
                            <>
                                {
                                    domainSearchResult &&
                                        <Stack tokens={{childrenGap: 10}}>
                                            <h3>Complete the organization profile</h3>
                                            <div>
                                                <TextField
                                                    id={`organizationName_editField`}
                                                    data-testid="orgName_InputField"
                                                    label={'Display Name'}
                                                    required={true}
                                                    role='textbox'
                                                    ariaLabel={`Organization Name field.`}
                                                    aria-describedby={`Organization Name field.`}
                                                    placeholder={`e.g. Contoso`}
                                                    value={orgProfileDto.organizationName}
                                                    onChange={_onUpdateOrgDisplayName}
                                                    onGetErrorMessage={(value:string) => {return orgDisplayNameErrorMsg}}
                                                    validateOnFocusOut
                                                />
                                            </div>
                                            <div>
                                                <Stack horizontal wrap tokens={{childrenGap: 10}} verticalAlign='center'>
                                                    <TextField
                                                        id={`organizationCity_textfield`}
                                                        data-testid="orgCity_InputField"
                                                        label={'City'}
                                                        required={false}
                                                        role='textbox'
                                                        ariaLabel={`Organization city field.`}
                                                        aria-describedby={`Organization city field.`}
                                                        placeholder={`e.g. Redmond`}
                                                        value={orgProfileDto.city}
                                                        onChange={_onUpdateOrgCityName}
                                                    />
                                                    <TextField
                                                        id={`organizationState_textfield`}
                                                        data-testid="orgState_InputField"
                                                        label={'State/Province'}
                                                        required={false}
                                                        role='textbox'
                                                        ariaLabel={`Organization state or province field.`}
                                                        aria-describedby={`Organization state or province field.`}
                                                        placeholder={`e.g. WA`}
                                                        value={orgProfileDto.state}
                                                        onChange={_onUpdateOrgProvinceName}
                                                    />
                                                    
                                                </Stack>
                                            </div>
                                            <div>
                                                <CountryDropdown
                                                    id={`organizationCountry_countryCombobox`}
                                                    onSelectCountry={_onSelectCountry}
                                                    selectedKey={orgProfileDto.country}
                                                    showErrorMessage={showCountryDropdownErrorMsg}
                                                />
                                            </div>
                                            <div>
                                                <OrganizationTypeDropdown
                                                    id={`organizationRoleList`}
                                                    selectedKeys={orgProfileDto.roleTypes}
                                                    onSelectOrgType={_onChangeRoleSelections}
                                                    showErrorMessage={showOrgRoleTypeDropdownErrorMsg}
                                                />
                                            </div>
                                            <br></br>
                                        </Stack>
                                }
                            </>
                        }
                    </Stack>
                }
                {
                        showSuccessScreen &&
                            <Stack styles={stackStyles} tokens={{childrenGap: 10}}>
                                <Label>
                                    Organization name
                                </Label>
                                {orgProfileDto.organizationName}
                                <Label>
                                    Domain
                                </Label>
                                {domainSearchResult}
                                <Label>
                                    Country/region
                                </Label>
                                {`${LocationConstants.CountryRegionDisplayName[orgProfileDto.country]} (${orgProfileDto.country})`}
                                <Label>
                                    Type
                                </Label>
                                {orgProfileDto.roleTypes.join(', ')}
                                <br></br>
                                <br></br>
                            </Stack>
                    }
                    {
                        showOnboardFailedScreen &&
                            <Stack styles={stackStyles} tokens={{childrenGap: 10}}>
                                <Label>
                                    Organization name
                                </Label>
                                {orgProfileDto.organizationName}
                                <Label>
                                    Domain
                                </Label>
                                {domainSearchResult}
                                <Label>
                                    Country/region
                                </Label>
                                {`${LocationConstants.CountryRegionDisplayName[orgProfileDto.country]} (${orgProfileDto.country})`}
                                <Label>
                                    Type
                                </Label>
                                {orgProfileDto.roleTypes.join(', ')}
                                <Label>
                                    Error Details
                                </Label>
                                {orgOnboardingError?.message ?? unknownOrgOnboardingError}
                                {
                                    orgOnboardingError?.correlationId &&
                                        <>
                                            <Label>
                                                Correlation Id
                                            </Label>
                                            {orgOnboardingError?.correlationId}
                                        </>
                                }
                                <br></br>
                                <br></br>
                            </Stack>
                    }
            </CoherenceModal>
        </>
    );
}