import { NotificationListItem } from "@coherence-design-system/controls";
import dayjs from "dayjs";

export class NotificationHelper {
    public static getRequestActionNotification(requestId: string, reviewAction: 'Approved' | 'Rejected', status: 'Succeed' | 'Failed', correlationId: string, message?: string): NotificationListItem {
        let newNotification: NotificationListItem = {
            itemKey: requestId,
            timeDelivered: dayjs(),
            status: 'unread',
            subjectIcon: 'Completed',
            subjectHeader: `Request ${reviewAction}`,
            displayStatus: 'new',
            messageBodyText: `${message} Correlation Id: ${correlationId}`,
          }; 

        return newNotification;
    }

    public static getErrorResponseNotification(actionName: string, message: string, correlationId: string, statusCode?: number): NotificationListItem {
        let newNotification: NotificationListItem = {
            itemKey: correlationId,
            timeDelivered: dayjs(),
            status: 'unread',
            subjectIcon: 'Error',
            subjectHeader: `Error with action ${actionName}`,
            displayStatus: 'new',
            messageBodyText: message,
          }; 

        return newNotification;
    }
}