import React, { useEffect, useState } from 'react';
import {
    ScrollablePane,
    Stack,
    IStackStyles,
    TextField,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    SelectionMode,
    mergeStyleSets,
    Selection,
    DefaultButton,  
    IStyle,
    Dropdown,
    IDropdownOption,
    DropdownMenuItemType,
    IDropdownStyles,
    IStackProps,
    IIconStyles,
    IStackTokens,
    Icon} from "@fluentui/react";
import { useBoolean } from '@fluentui/react-hooks';
import { SearchUserResult } from '../../DataModels/SearchUserResult';
import { IAMService } from '../../Services/IAMService';
import { ErrorScreenTemplate } from '../../DataModels/ErrorScreenTemplate';
import { IGenericDialogTemplate } from '../../DataModels/GenericDialogTemplate';
import { GenericResponseError } from '../../DataModels/Errors/GenericResponseError';
import { ActionConfirmationDialog } from '../../Components/ActionConfirmationDialog';
import { useWindowDimensions } from '../../Utilities/UseWindowDimensions';
import * as SiteStyle from '../Styles';
import { AdminPortalFooter } from '../../AdminPortalFooter';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { IAppInsightsLogger } from '../../Utilities/IAppInsightsLogger';
import { Organization } from '../../DataModels/Organization';
import { IErrorStateComponentProps } from '@coherence-design-system/controls';
import { CreateUserDto } from '../../DataModels/CreateUserDto';
import { GenericHelper } from '../../Utilities/GenericHelper';

const tokens = {
    stackTokens: {
      childrenGap: 20,
      maxHeight: 100,
    },
    spinnerStack: {
      childrenGap: 20,
    },
  };
const stackStyles: Partial<IStackStyles> = { root: { justifyContent:'left',alignItems:'left', paddingLeft: 10, paddingRight: 10, width: 320 } };
const buttonStackStyles: Partial<IStackStyles> = { root: { paddingBottom: 40 , marginLeft:0} };
const inputBoxStyle = { minWidth: 80};
const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { minWidth: 320, marginLeft:-10, marginRight:-10 , marginBottom:-5 } };
const itemTitleStyles: React.CSSProperties = {
    alignItems: 'left',
    display: 'flex',
    justifyContent: 'left',
    marginLeft:-10,
    marginBottom:5,
    font:'Segoe UI',
    fontSize:14,
    fontWeight:600
};

const loadingIndicatorRowProps: IStackProps = { horizontal: false, verticalAlign: 'center' };
const spinnerStyle: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
};

interface ICreateUserProfilePageProps {
    iamService: IAMService
    logger: IAppInsightsLogger;
}


export function CreateUserProfilePage(props: ICreateUserProfilePageProps) {
    const { width, height } = useWindowDimensions();
    const [isLoadingOrganization, setLoadingOrganizationStatus] = useState(true);
    const [disableOrganizationDropdown, setDisableOrganizationDropdown] = useState(true);
    const [shouldShowIndicator, setShowIndicator] = useState(false);
    const [options, setOptions] = useState<IDropdownOption[]>([]);
    const [submitButtonDisabled, setDisableSubmitButton] = useState(true);
    const [disableInputFields, setDisableInputField] = useState(false);
    const [resetButtonClicked, setResetButtonClicked] = useState(false);

    const [showDialog, {toggle: toggleHideDialog}] = useBoolean(true);
    const [dialogMessage, setDialogMessage] = useState<IGenericDialogTemplate>();
    const [showModal, {toggle: toogleShowModal}] = useBoolean(false);
    const [selectedOrganization, setSelectedOrganization] = React.useState<IDropdownOption>();


    var createUserDto: CreateUserDto = {
        user : {
            email : '',
            firstName :'',
            lastName : '',
            jobTitle : '',
            organization:''
        },
        businessJustification : '',
        microsoftBusinessContact : '',
        notifyUser : false,
        organizationId : '',
        requestedBy: '',
    };

    const [createUser, setCreateUser] = useState<CreateUserDto>(createUserDto);

    var errorScreenBody: IErrorStateComponentProps | undefined;

    const requiredErrorMessage = (value: string, controlName: string) => {
        if(!isLoadingOrganization)
        {
            if(value.length == 0)
            {
                return `${controlName} is required` ;
            }
        }
        return '';
      };

      const emailValidation = (value: string, controlName: string) => {
        if(!isLoadingOrganization)
        {
            let emailFormatInvalid = false;

            if(value.length == 0)
            {
                return `${controlName} is required` ;
            }
            
            if(controlName == 'Microsoft Buisness Contact' && (!GenericHelper.validateEmail(value) ||  !value.endsWith('@microsoft.com') ))
            {
                return 'A valid Microsoft Email is required. E.g. "user@microsoft.com".';
            }

            if(!GenericHelper.validateEmail(value))
            {
                return 'A valid email address is required. E.g. joe@contoso.com';
            }
        }
        return '';
      };

    useEffect(() => {
          if(isLoadingOrganization){
            setShowIndicator(true);
            props.iamService.getOrganizations()
                .then((organizationList: Organization[]) => {
                    let orgList:IDropdownOption[] = [];
                    organizationList.forEach(function(org) {
                        let orgLocation = org.name + "," + org.country;
                        orgLocation += org.state === undefined || org.state.length == 0 ? '' : ',' + org.state ;
                        orgLocation += org.city === undefined || org.city.length == 0 ? '' : ',' + org.city ;
                        orgList.push({key: org.organizationId, text: orgLocation})
                    });
                    setOptions(orgList);
                    setShowIndicator(false);
                    setLoadingOrganizationStatus(false);
                    setDisableOrganizationDropdown(false);
                })
                .catch((error) => {
                    props.logger.trackException(error, SeverityLevel.Critical);
                });
            }
    },[isLoadingOrganization]);
   
    const _onResetClicked = () => {
        setCreateUser({
            user : {
                email : '',
                firstName :'',
                lastName : '',
                jobTitle : '',
                organization: '',
            },
            businessJustification : '',
            microsoftBusinessContact : '',
            notifyUser : false,
            organizationId : '',
            requestedBy: '',
        });
        setSelectedOrganization(undefined);
        setResetButtonClicked(true);
        setTimeout(() => {
            setResetButtonClicked(false);
         }, 2000);        
    }

    const _onSubmitClicked = () => {
         setShowIndicator(true);
         setDisableSubmitButton(true);
         setDisableInputField(true);
         setDisableOrganizationDropdown(true);
         
        props.iamService.onboardUser({...createUser})
            .then(result => {
                setShowIndicator(false);
                setDisableInputField(false);
                setDisableSubmitButton(false);
                setDisableOrganizationDropdown(false);
                
                setDialogMessage({
                    title: 'Create User',
                    actionType: 'Create User',
                    messageBody: 'User is successfully onboarded.',
                    isError: true
                });

                toggleHideDialog();
                setCreateUser({
                    user : {
                        email : '',
                        firstName :'',
                        lastName : '',
                        jobTitle : '',
                        organization: '',
                    },
                    businessJustification : '',
                    microsoftBusinessContact : '',
                    notifyUser : false,
                    organizationId : '',
                    requestedBy: '',
                });
                setSelectedOrganization(undefined);
            })
            .catch(error => {
                setShowIndicator(false);
                setDisableInputField(false);
                setDisableOrganizationDropdown(false);
                var errorMessage = ``;
                if (error instanceof GenericResponseError) {
                    var responseError = error as GenericResponseError;
                    errorMessage = `Failed to add user. ${responseError.message}. Error status code: ${responseError.code}. Correlation Id: ${responseError.correlationId}.`
                }
                else {
                    errorMessage = `Failed to add user. ${error.message}.`
                }

                setDialogMessage({
                    title: `Failed To Add User`,
                    actionType: 'Create User',
                    messageBody: errorMessage,
                    isError: true
                });

                toggleHideDialog();
                setDisableSubmitButton(false);
            });
    }


    const onOrganizationChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setSelectedOrganization(item);
        var updatedObject:CreateUserDto = {...createUser, organizationId:item.key as string, user: {...createUser.user, organization:item.text}} 
        setCreateUser(updatedObject);
    };

    const handleChange = (event: any, controlName:string) => 
    {
            var updatedObject:CreateUserDto = {...createUser} ;  
            switch(controlName)
            {
                case 'Email' : 
                {
                    updatedObject = {...createUser, user: {...createUser.user, email:event.target.value}}
                }
                break;
                case 'FirstName' : 
                {
                    updatedObject = {...createUser, user: {...createUser.user, firstName:event.target.value}}
                }
                break;
                case 'LastName' : 
                {
                    updatedObject = {...createUser, user: {...createUser.user, lastName:event.target.value}}
                }
                break;
                case 'JobTitle' : 
                {
                    updatedObject = {...createUser, user: {...createUser.user, jobTitle:event.target.value}}
                }
                break;
                case 'Microsoft Buisness Contact' : 
                {
                    updatedObject = {...createUser,  microsoftBusinessContact: event.target.value}
                }
                break;
                case 'Organization' : 
                {
                    updatedObject = {...createUser, organizationId:event.target.value}
                }
                break;
                case 'BusinessJustification' : 
                {
                    updatedObject = {...createUser, businessJustification:event.target.value}
                }
                break;
            }
            setCreateUser(updatedObject);
            if(emailValidation(updatedObject.user.email,'Email') == '' 
                && updatedObject.user.firstName.length > 0
                 && updatedObject.user.lastName.length > 0 
                 && updatedObject.user.jobTitle.length > 0
                 && updatedObject.businessJustification.length > 0
                 && emailValidation(updatedObject.microsoftBusinessContact,'Microsoft Buisness Contact') == '' )
                 {
                    setDisableSubmitButton(false);
                 }
                 else
                 {
                     setDisableSubmitButton(true);
                 }
    }

    return (
        <>
            <ScrollablePane>
                <Stack tokens={tokens.stackTokens} className={SiteStyle._Styles.mainAreaStyle} >
                    <h1 role='heading'>Create User</h1>
                    <h2 role='heading'>Add user to existing organization</h2>
                    <Stack wrap tokens={tokens.stackTokens}  styles={stackStyles}>
                            <TextField style={inputBoxStyle} disabled={disableInputFields}  value={createUser.user.email} onChange={event => handleChange(event,'Email')}  label="Email" type='email' data-testid="txtUserEmail" placeholder="username@contoso.com" maxLength={320} onGetErrorMessage={(value:string) => {return emailValidation(value,"Email");}} validateOnFocusOut />
                            <TextField style={inputBoxStyle} label="First Name" data-testid="txtUserFirstName" disabled={disableInputFields}  value={createUser.user.firstName} onChange={event => handleChange(event,'FirstName')}  maxLength={100} onGetErrorMessage={(value:string) => {return requiredErrorMessage(value,"First name");}} validateOnFocusOut />
                            <TextField style={inputBoxStyle} label="Last Name" data-testid="txtLastName" disabled={disableInputFields}  value={createUser.user.lastName} onChange={event => handleChange(event,'LastName')} maxLength={100} onGetErrorMessage={(value:string) => {return requiredErrorMessage(value,"Last name");}} validateOnFocusOut />
                            <div>
                                <span style={itemTitleStyles}>Choose an organization</span>
                            <Dropdown styles={dropdownStyles} selectedKey={selectedOrganization ? selectedOrganization.key : undefined} data-testid="ddlOrganization" options={options} onChange={onOrganizationChange} disabled={disableOrganizationDropdown}/>
                            </div>
                            <TextField style={inputBoxStyle} label="Job Title" data-testid="txtJobTitle" disabled={disableInputFields}  value={createUser.user.jobTitle} onChange={event => handleChange(event,'JobTitle')} maxLength={50}  onGetErrorMessage={(value:string) => {return requiredErrorMessage(value,"Job title");}} validateOnFocusOut/>
                            <TextField style={inputBoxStyle} label="Microsoft Business Contact" disabled={disableInputFields}  value={createUser.microsoftBusinessContact} onChange={event => handleChange(event,'Microsoft Buisness Contact')} data-testid="txtmsftcontact" placeholder="username@microsoft.com" maxLength={320} onGetErrorMessage={(value:string) => {return emailValidation(value,"Microsoft Buisness Contact");}} validateOnFocusOut />
                            <TextField  style={inputBoxStyle} label="Business Justification" value={createUser.businessJustification} disabled={disableInputFields}  onChange={event => handleChange(event,'BusinessJustification')} data-testid="txtBusinessJustification" placeholder="Why does user need access to role." multiline rows={3} maxLength={200} onGetErrorMessage={(value:string) => {return requiredErrorMessage(value, "Business justification");}} validateOnFocusOut/>
                     <Stack horizontal tokens={tokens.stackTokens} styles={buttonStackStyles} >
                        <PrimaryButton text='Create' data-testid="btnCreateUser" onClick={_onSubmitClicked} disabled={submitButtonDisabled}></PrimaryButton>
                        <DefaultButton text='Reset' type='reset' onClick={_onResetClicked} ariaDescription="Clear the fields" data-testid="btnReset"/>
                        {
                            resetButtonClicked && 
                                <div id="alertResetMessage" role="alert" aria-label='Reset button pressed. Your previous selections have been cleared.'></div>
                        }
                        {
                                    shouldShowIndicator &&
                                        <Spinner size={SpinnerSize.medium} labelPosition='right'/>
                        }
                    </Stack>
                    
                    <div role="status">
                    {
                        isLoadingOrganization &&
                      
                            <div>
                                    Loading organization...
                            </div>
                    }
                    </div>
                    {
                        <div>
                            <AdminPortalFooter />
                        </div>
                    }
                </Stack>
                </Stack>
            </ScrollablePane>
            {
                dialogMessage &&
                    <ActionConfirmationDialog
                        dialogData={dialogMessage}
                        showDialog={showDialog}
                        onDismiss={toggleHideDialog}
                        data-testid="dialogBox"
                    />
            }
        </>
    );
}