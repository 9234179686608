import React, {
    useState,
} from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import {
    ScrollablePane,
    Stack,
    IStackStyles,
    TextField,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    SelectionMode,
    DetailsListLayoutMode,
    FocusTrapZone,
    CommandBar,
    ICommandBarItemProps,
    ConstrainMode
} from "@fluentui/react";
import * as SiteStyle from '../Styles';
import { CoherenceDataGrid } from '@coherence-design-system/controls';
import { OrgManagementGridColumns } from '../../Constants/OrgManagementGridColumn';
import { OrganizationOnboardModal } from '../../Components/OrgManagement/OrganizationOnboardModal';
import { IAMService } from '../../Services/IAMService';
import { GenericResponseError } from '../../DataModels/Errors/GenericResponseError';
import { IGenericDialogTemplate } from '../../DataModels/GenericDialogTemplate';
import { unknownDomainMessage } from '../../Constants/SiteConstants';
import { ActionConfirmationDialog } from '../../Components/ActionConfirmationDialog';
import { OrganizationDomainCheckResponse } from '../../DataModels/OrganizationDomainCheckResponse';

const tokens = {
    stackTokens: {
        childrenGap: 20,
        maxHeight: 100,
    },
    spinnerStack: {
        childrenGap: 20,
    },
};
const stackStyles: Partial < IStackStyles > = {
    root: {
        justifyContent: 'left',
        alignItems: 'left',
        paddingLeft: 10,
        paddingRight: 10
    }
};
const domainInputBoxStyle = { minWidth: 250 };

interface IOrgManagementAppOwnerPageProps {
    iamService: IAMService;
}

export function OrgManagementAppOwnerPage(props: IOrgManagementAppOwnerPageProps) {
    
    const [showNewOrgWizard, setShowNewOrgWizard] = useState(false);
    const [showErrorDialog, {toggle: toggleHideErrorDialog}] = useBoolean(true);
    
    // Domain search box related
    const [domainSearchInput, setDomainSearchInput] = useState<string>("");
    const [searchButtonDisabled, {toggle: toggleSearchButtonDisabled}] = useBoolean(false);
    const [shouldShowIndicator, setShowIndicator] = useState(false);
    const [hasSearchDone, setHasSearchDone] = useState(false);

    // Organization Profile related
    const [checkDomainResult, setCheckDomainResult] = useState<OrganizationDomainCheckResponse>();
    const [errorDialogMessage, setErrorDialogMessage] = useState<IGenericDialogTemplate>();

    const _onAddNewOrganizationClicked = () => {
        setShowNewOrgWizard(true);
    }

    const _onAddNewOrganizationClose = () => {
        setShowNewOrgWizard(false);
        _onDomainSearchClicked();
    }

    const _onDomainSearchTextChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDomainSearchInput(e.currentTarget.value);
    }

    const _onDomainSearchClicked = async () => {
        setShowIndicator(true);
        setHasSearchDone(false);

        props.iamService.getOrganizationsByDomain(domainSearchInput)
            .then(result => {
                setCheckDomainResult(result);
                if (result.domainOnboarded) {
                    setShowIndicator(false);
                    setHasSearchDone(true);
                } else {
                    setErrorDialogMessage({
                        title: `Unknown Domain - Contact IAM Support Team`,
                        actionType: 'Organization Onboarding',
                        messageBody: unknownDomainMessage,
                        isError: false
                    });
                    setShowIndicator(false);
                    setHasSearchDone(true);
                    toggleHideErrorDialog();
                }
            })
            .catch(error => {
                var errorMessage = ``;
                if (error instanceof GenericResponseError) {
                    var responseError = error as GenericResponseError;
                    errorMessage = `Failed to submit organization onboarding request. ${responseError.message}. Error status code: ${responseError.code}. Correlation Id: ${responseError.correlationId}.`
                }
                else {
                    errorMessage = `Failed to submit organization onboarding request. ${error.message}.`
                }

                setErrorDialogMessage({
                    title: `Failed To Submit Organization Onboarding Request`,
                    actionType: 'Organization Onboarding',
                    messageBody: errorMessage,
                    isError: true
                });
                toggleHideErrorDialog();
            });
    }

    function getOrgManagementGridCommandBarItems(addOrgCallback: () => void): ICommandBarItemProps[] {
        return [
            {
                key: 'newOrg',
                text: 'New Organization',
                cacheKey: 'newOrgCacheKey', // changing this key will invalidate this item's cache
                iconProps: { iconName: 'Add' },
                onClick: addOrgCallback,
                disabled: !hasSearchDone || !(checkDomainResult?.domainOnboarded ?? false)
            },
        ]
    } 

    return (
        <>
            <ScrollablePane>
                <Stack tokens={tokens.stackTokens} className={SiteStyle._Styles.mainAreaStyle}>
                    <h1 role='heading'>Create Organization Profiles</h1>
                    <Stack horizontal wrap tokens={tokens.stackTokens} verticalAlign='end' styles={stackStyles}>
                            <TextField
                                style={domainInputBoxStyle}
                                label="Enter the organization's email domain"
                                data-testid="textSearchDomain"
                                placeholder="contoso.com"
                                onChange={(e) => _onDomainSearchTextChange(e)}
                            />
                            <Stack horizontal tokens={tokens.stackTokens} verticalAlign='center' style={{marginBottom: 0}}>
                                <PrimaryButton text='Search Domain' data-testid="btnSearchUser" onClick={_onDomainSearchClicked} disabled={searchButtonDisabled || domainSearchInput.length == 0}></PrimaryButton>
                                {
                                    shouldShowIndicator &&
                                        <Spinner size={SpinnerSize.medium} labelPosition='right'/>
                                }
                            </Stack>
                    </Stack>
                    <div role="status">
                    {
                        !shouldShowIndicator &&
                        hasSearchDone &&
                        checkDomainResult?.domainOnboarded &&
                        checkDomainResult?.onboardedOrganiztionsMasterData.length == 0 &&
                        <div data-testid="domainSearchResult-empty">
                            The domain is valid, but has been linked to 0 organization. Click "New Organization" to link it to an existing organization, or a new organization.
                        </div>
                    }
                    {
                        !shouldShowIndicator &&
                        hasSearchDone &&
                        checkDomainResult?.domainOnboarded &&
                        checkDomainResult?.onboardedOrganiztionsMasterData.length != 0 &&
                        <div data-testid="domainSearchResult-success">
                            The domain is valid and linked to {checkDomainResult?.onboardedOrganiztionsMasterData.length} organization(s). Click "New Organization" to link it to a new organization.
                        </div>
                    }
                    {
                        !shouldShowIndicator &&
                        hasSearchDone &&
                        !checkDomainResult?.domainOnboarded &&
                        <div data-testid="domainSearchResult-unknown">
                            Unknown domain. {unknownDomainMessage}
                        </div>
                    }
                    </div>
                    <FocusTrapZone disabled={true}>
                        <CommandBar
                            items={getOrgManagementGridCommandBarItems(_onAddNewOrganizationClicked)}
                            ariaLabel="Inbox actions"
                            primaryGroupAriaLabel="Email actions"
                            farItemsGroupAriaLabel="More actions"
                            styles={{root: {paddingLeft: 0}}}
                        />
                    </FocusTrapZone>
                    <div>
                            <CoherenceDataGrid
                                listConfig={OrgManagementGridColumns}
                                data={checkDomainResult?.onboardedOrganiztionsMasterData ?? []}
                                selectionMode={SelectionMode.none}
                                isSortable={true} // allow the user to choose the sort on this DataGrid
                                ariaLabelForSelectAllCheckbox={'select all roles'}
                                checkButtonAriaLabel={'checkbox'}
                                isScrollable={true}
                                layoutMode={DetailsListLayoutMode.justified}
                                constrainMode={ConstrainMode.horizontalConstrained}
                            />
                            <br></br>
                    </div>
                </Stack>
                <br></br>
            </ScrollablePane>
            {
                showNewOrgWizard &&
                    <OrganizationOnboardModal
                        onDismiss={_onAddNewOrganizationClose}
                        domainSearchInput={domainSearchInput}
                        iamService={props.iamService}
                    />
            }
            {
                errorDialogMessage &&
                    <ActionConfirmationDialog
                        dialogData={errorDialogMessage}
                        showDialog={showErrorDialog}
                        onDismiss={toggleHideErrorDialog}
                    />
            }
        </>
    );
}