import React, { useState } from 'react';
import { Card, CardStandardHeader, NotificationListItem } from '@coherence-design-system/controls';
import { PrimaryButton, DefaultButton, Stack, IStackTokens, TextField, Spinner, SpinnerSize, Label, } from '@fluentui/react';

import { AccessRequestDto } from '../DataModels/AccessRequestDto';
import { IAMService } from '../Services/IAMService';
import { RequestActionDto } from '../DataModels/RequestActionDto';
import { NotificationHelper } from '../Utilities/NotificationHelper';

import { v4 as uuidv4 } from 'uuid';
import { IMetricTelemetry } from '@microsoft/applicationinsights-web';

const stackTokens: IStackTokens = { childrenGap: 12, padding: 12 };

interface IAccessRequestCardProps {
    requestData: AccessRequestDto;
    iamService: IAMService;
    onActionSucceeds: (request: AccessRequestDto, actionName: 'approve' | 'reject') => void;
    onActionFails: (request: AccessRequestDto, actionName: 'approve' | 'reject', error: Error) => void;
    notificationHandler: (newNotification: NotificationListItem) => void;
}

const contentStackTokens: IStackTokens = { childrenGap: 10 };

const itemTitleStyles: React.CSSProperties = {
    alignItems: 'left',
    display: 'flex',
    justifyContent: 'left',
    fontWeight: 'bold',
};

const textFieldStyles: React.CSSProperties = {
    maxWidth: '400px',
    width: '350px',
    minWidth: '200px'
};

const getHeader = (requestId: string) => {
    return (
        <CardStandardHeader
            cardTitle={'Request Id: ' + requestId}
        />
    )
}

export function AccessRequestCard(props: IAccessRequestCardProps) {
    let requestData = props.requestData;
    let onActionSucceedsCallBack = props.onActionSucceeds;
    let iamServiceClient = props.iamService;

    const [reviewJustification, setReviewJustification] = useState<string>();
    const [isSendingAction, setSendingActionState] = useState(false);

    const _onReviewJustificationChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setReviewJustification(newValue);
    };

    function ApproveRequest(request: AccessRequestDto, onActionSucceeds: Function): void {
        var requestAction: RequestActionDto = {
            requestId: request.requestId,
            justification: reviewJustification!,
            notifyUser: false,
        };

        setSendingActionState(true);
        iamServiceClient.approveRequest(requestAction).then(() => {
            setSendingActionState(false);
            let newNotification = NotificationHelper.getRequestActionNotification(request.requestId, 'Approved', 'Succeed', uuidv4(), `Approved request for user ${request.email}`);
            props.notificationHandler(newNotification);
        
            onActionSucceeds(request, 'approve');
        })
        .catch(error => {
            setSendingActionState(false);
            props.onActionFails(request, 'approve', error);
        })
    }
    
    function RejectRequest(request: AccessRequestDto): void {
        var requestAction: RequestActionDto = {
            requestId: request.requestId,
            justification: reviewJustification!,
            notifyUser: false,
        };

        setSendingActionState(true);
        iamServiceClient.rejectRequest(requestAction).then(() => {
            setSendingActionState(false);
            let newNotification = NotificationHelper.getRequestActionNotification(request.requestId, 'Rejected', 'Succeed', uuidv4(), `Rejected request for user ${request.email}`);
            props.notificationHandler(newNotification);

            props.onActionSucceeds(request, 'reject');
        })
        .catch(error => {
            setSendingActionState(false);
            props.onActionFails(request, 'reject', error);
        });
    }
    
    return (
            <div
                style={{
                    maxWidth: '808px',
                    minHeight: '310px',
                }}
            >
                <Card 
                    header={
                        getHeader(requestData.requestId)
                    }
                    footer={
                        <Stack>
                            <Stack horizontal tokens={stackTokens}>
                                <PrimaryButton 
                                    text="Approve"
                                    role='button'
                                    ariaLabel={`Approve request button`}
                                    ariaDescription={`Approve request button for role ${requestData.requestedRoleName} from user ${requestData.email}`}
                                    onClick={() => ApproveRequest(requestData, onActionSucceedsCallBack)} 
                                    disabled={!reviewJustification || isSendingAction}
                                    aria-disabled={!reviewJustification || isSendingAction}
                                    checked={false} />
                                <DefaultButton 
                                    text="Reject" 
                                    role='button'
                                    ariaLabel={`Reject request button`}
                                    ariaDescription={`Reject request button for role ${requestData.requestedRoleName} from user ${requestData.email}`}
                                    onClick={() => RejectRequest(requestData)} 
                                    disabled={!reviewJustification || isSendingAction}
                                    aria-disabled={!reviewJustification || isSendingAction}
                                    checked={false} />
                                {
                                    isSendingAction &&
                                        <Spinner size={SpinnerSize.medium} labelPosition='right'/>
                                }
                            </Stack>
                        </Stack>
                    }>
                    <Stack horizontal wrap horizontalAlign="space-between">
                        <Stack tokens={contentStackTokens}>
                            <span>
                                <label style={itemTitleStyles}>Email: </label> 
                                <label>{requestData.email}</label>
                            </span>
                            <span>
                                <label style={itemTitleStyles}>Microsoft Business Contact: </label>
                                <label>{requestData.microsoftBusinessContact}</label>
                            </span>
                        </Stack>
                        <Stack tokens={contentStackTokens}>
                            <span>
                                <label style={itemTitleStyles}>Name: </label> 
                                <label>{requestData.userName}</label>
                            </span>
                            <span>
                                <label style={itemTitleStyles}>Job Title: </label>
                                <label>{requestData.jobTitle}</label>
                            </span>
                        </Stack>
                        <Stack tokens={contentStackTokens}>
                            <span>
                                <label style={itemTitleStyles}>Organization: </label> 
                                <label>{requestData.organization}</label>
                            </span>
                            <span>
                                <label style={itemTitleStyles}>Requested Role: </label>
                                <label>{requestData.requestedRoleName}</label>
                            </span>
                        </Stack>
                    </Stack>
                    <Stack horizontal wrap horizontalAlign="space-between" tokens={{padding: '10px 10px 0 0', childrenGap: 10}}>
                        <div>
                            <Label htmlFor={`requestJustificaticationTextField_${requestData.requestId}`} style={itemTitleStyles}>Business Justification</Label>
                            <TextField
                                id={`requestJustificaticationTextField_${requestData.requestId}`}
                                role='textbox'
                                style={textFieldStyles}
                                ariaLabel={`Business justification of the request for role ${requestData.requestedRoleName} from user ${requestData.email}`}
                                aria-describedby={`requestJustificaticationTextField_${requestData.requestId}`}
                                readOnly
                                multiline
                                defaultValue={requestData.businessJustification}/>
                        </div>
                        <div>
                            <Label required htmlFor={`reviewerJustificaticationTextField_${requestData.requestId}`} style={itemTitleStyles}>Reviewer Justification</Label>
                            <TextField
                                id={`reviewerJustificaticationTextField_${requestData.requestId}`}
                                role='textbox'
                                style={textFieldStyles} 
                                ariaLabel={`Required Reviewer justification input field of the request for role ${requestData.requestedRoleName} from user ${requestData.email}`}
                                aria-describedby={`reviewerJustificaticationTextField_${requestData.requestId}`}
                                multiline
                                placeholder="Required field. E.g. Approved/Rejected request because..." 
                                data-testid ="approverJustification"
                                onChange={_onReviewJustificationChange} />
                        </div>
                    </Stack>
                </Card>
            </div>
    );
}