import React, { useState } from 'react';
import {
    ScrollablePane,
    Stack,
    IStackStyles,
    TextField,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    SelectionMode,
    Selection,
    DefaultButton,  
    DetailsListLayoutMode} from "@fluentui/react";
import { CoherenceDataGrid, ErrorStateComponent, IErrorStateComponentProps } from '@coherence-design-system/controls';
import { useBoolean } from '@fluentui/react-hooks';
import { columns, Role } from '../../DataModels/Role';
import { SearchUserResult } from '../../DataModels/SearchUserResult';
import { IAMService } from '../../Services/IAMService';
import { SearchUserNotFoundError } from '../../DataModels/Errors/SearchUserNotFoundError';
import { ErrorScreenTemplate } from '../../DataModels/ErrorScreenTemplate';
import { IGenericDialogTemplate } from '../../DataModels/GenericDialogTemplate';
import { GenericResponseError } from '../../DataModels/Errors/GenericResponseError';
import { ActionConfirmationDialog } from '../../Components/ActionConfirmationDialog';
import { useWindowDimensions } from '../../Utilities/UseWindowDimensions';
import * as SiteStyle from '../Styles';
import { RequestConfirmationModal } from '../../Components/RequestConfirmationModal';
import { AccessRequestResult } from '../../DataModels/AccessRequestResult';
import { AdminPortalFooter } from '../../AdminPortalFooter';

const tokens = {
    stackTokens: {
      childrenGap: 20,
      maxHeight: 100,
    },
    spinnerStack: {
      childrenGap: 20,
    },
  };
const stackStyles: Partial<IStackStyles> = { root: { justifyContent:'left',alignItems:'left', paddingLeft: 10, paddingRight: 10 } };
const buttonStackStyles: Partial<IStackStyles> = { root: { paddingBottom: 40 } };
const emailInputBoxStyle = { minWidth: 250 };

const selection = new Selection({
    getKey: (item: any, index?: number): string => {
        return item.id;
    },
    canSelectItem: (item: Role, index?: number) => {
        return !item.isAssigned;
    }
});

interface IUserManagementHomePageProps {
    iamService: IAMService
}

export function UserManagementHomePage(props: IUserManagementHomePageProps) {
    const [shouldShowIndicator, setShowIndicator] = useState(false);
    const [userBaseInformation, setUserInformation] = useState<SearchUserResult>();
    const [roleList, setRoleList] = useState<Role[]>([]);
    const [searchBoxInput, setSearchBoxInput] = useState<string>('');
    const [searchButtonDisabled, setDisableSearchButton] = useState(false);
    const [submitButtonDisabled, setDisableSubmitButton] = useState(true);
    const [showUserNotFoundScreen, setUserNotFoundScreen] = useState(false);
    const [showNetworkErrorScreen, setNetworkErrorScreen] = useState(false);
    const [resetButtonClicked, setResetButtonClicked] = useState(false);

    const [showDialog, {toggle: toggleHideDialog}] = useBoolean(true);
    const [dialogMessage, setDialogMessage] = useState<IGenericDialogTemplate>();
    const [showModal, {toggle: toogleShowModal}] = useBoolean(false);
    const [responseMessage, setResponseMessage] = useState<AccessRequestResult>();

    var errorScreenBody: IErrorStateComponentProps | undefined;

    const _onSearchClicked = () => {
        setShowIndicator(true);
        setUserNotFoundScreen(false);
        setNetworkErrorScreen(false);
        setRoleList([]);
        props.iamService.searchUser(searchBoxInput)
            .then(result => {
                 _onSearchDone(result); 
            })
            .catch(error => {
                if (error instanceof SearchUserNotFoundError) {
                    // Show user not found screen
                    _onSearchFail();
                }
                _showGenericErrorMessage(error);
            });
    }

    const _onResetClicked = () => {
        selection.setAllSelected(false);
        setResetButtonClicked(true);
        setTimeout(() => {
            setResetButtonClicked(false);
         }, 2000);        
    }

    const _onSubmitClicked = () => {
        let selectedRoles = selection.getSelection();

        if (!userBaseInformation) {
            throw Error();
        }

        setShowIndicator(true);
        setDisableSubmitButton(true);
        setDisableSearchButton(true);
        props.iamService.assignRolesToUser(userBaseInformation.userEmail, selectedRoles?.filter(r => r.id).map(r => r.id!) ?? [])
            .then(result => {
                setShowIndicator(false);
                setResponseMessage(result);
                toogleShowModal();
                setDisableSubmitButton(false);
                setDisableSearchButton(false);
            })
            .catch(error => {
                setShowIndicator(false);

                var errorMessage = ``;
                if (error instanceof GenericResponseError) {
                    var responseError = error as GenericResponseError;
                    errorMessage = `Failed to submit role assignment request. ${responseError.message}. Error status code: ${responseError.code}. Correlation Id: ${responseError.correlationId}.`
                }
                else {
                    errorMessage = `Failed to submit role assignment request. ${error.message}.`
                }

                setDialogMessage({
                    title: `Failed To Submit Role Assignment Request`,
                    actionType: 'Role Assignment',
                    messageBody: errorMessage,
                    isError: true
                });
                toggleHideDialog();
                setDisableSubmitButton(false);
                setDisableSearchButton(false);
            });
    }

    const _onSearchDone = (userProfile: SearchUserResult) => {
        setShowIndicator(false);
        setDisableSubmitButton(false);
        setUserInformation(userProfile);
        _loadRoles(userProfile.userId);
    }

    const _onSearchFail = () => {
        setShowIndicator(false);
        setUserNotFoundScreen(true);
    }

    const _loadRoles = (userId: string) => {
        setShowIndicator(true);
        props.iamService.getRolesForUser(userId)
            .then(result => {
                _onRolesFetched(result);
            });
    }

    const _onRolesFetched = (roles: Role[]) => {
        setShowIndicator(false);
        setRoleList(roles);
    }

    const _onSearchTextChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearchBoxInput(e.currentTarget.value);
    }

    const _showGenericErrorMessage = (error: Error) => {
        errorScreenBody = ErrorScreenTemplate.getNetworkError(error);
        setNetworkErrorScreen(true);
    }

    const _ariaLabelForRowItem = (item: Role) => {
        return `Role name ${item.name}, role type ${item.type}, please press Ctrl + Space to select or unselect this role.`;
    }

    return (
        <>
            <ScrollablePane>
                <Stack tokens={tokens.stackTokens} className={SiteStyle._Styles.mainAreaStyle}>
                    <h1 role='heading'>User Role Assignment</h1>
                    <Stack horizontal wrap tokens={tokens.stackTokens} verticalAlign='end' styles={stackStyles}>
                            <TextField style={emailInputBoxStyle} label="Enter the user's email" data-testid="textSearchUser" placeholder="username@contoso.com" onChange={(e) => _onSearchTextChange(e)}/>
                            <Stack horizontal tokens={tokens.stackTokens} verticalAlign='center' style={{marginBottom: 0}}>
                                <PrimaryButton text='Search' data-testid="btnSearchUser" onClick={_onSearchClicked} disabled={searchButtonDisabled || searchBoxInput.length == 0}></PrimaryButton>
                                {
                                    shouldShowIndicator &&
                                        <Spinner size={SpinnerSize.medium} labelPosition='right'/>
                                }
                            </Stack>
                    </Stack>
                    <div role="status">
                    {
                        !shouldShowIndicator && 
                        !showUserNotFoundScreen && 
                        !showNetworkErrorScreen &&
                        userBaseInformation &&
                            <div>
                                <div>User Name: {userBaseInformation.userName}</div>
                                <br></br>
                                <div>
                                    Select the functional roles to grant. For keyboard users, please use Ctrl + Space to select or unselect roles.
                                </div>
                            </div>
                    }
                    </div>
                    {
                        !showUserNotFoundScreen && 
                        !showNetworkErrorScreen &&
                            <div>
                                <CoherenceDataGrid
                                    listConfig={columns}
                                    data={roleList}
                                    selectionMode={SelectionMode.multiple}
                                    isSortable={true} // allow the user to choose the sort on this DataGrid
                                    selection={selection}
                                    ariaLabelForSelectAllCheckbox={'select all roles'}
                                    checkButtonAriaLabel={'checkbox'}
                                    isScrollable={true}
                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                    selectionZoneProps={{ isSelectedOnFocus: false, selection: selection }}
                                    getRowAriaLabel={_ariaLabelForRowItem}
                                />
                            </div>
                    }
                    <Stack horizontal tokens={tokens.stackTokens} verticalAlign="center" styles={buttonStackStyles}>
                        <PrimaryButton text='Submit' data-testid="btnSubmitAssignRole" onClick={_onSubmitClicked} disabled={roleList.length == 0 || submitButtonDisabled}></PrimaryButton>
                        <DefaultButton text='Reset' type='reset' onClick={_onResetClicked} ariaDescription="Unselect all the roles from the available role list"/>
                        {
                            resetButtonClicked && 
                                <div id="alertResetMessage" role="alert" aria-label='Reset button pressed. Your previous selections have been cleared.'></div>
                        }
                    </Stack>
                    {
                        <div>
                            <AdminPortalFooter />
                        </div>
                    }
                </Stack>
                {
                    showUserNotFoundScreen &&
                        <ErrorStateComponent {...ErrorScreenTemplate.userNotFound} />
                }
                {
                    showNetworkErrorScreen &&
                        <ErrorStateComponent {...errorScreenBody} />
                }
                <br></br>
            </ScrollablePane>
            {
                responseMessage && 
                    <RequestConfirmationModal
                        isOpen={showModal}
                        requestResponse={responseMessage}
                        onDismiss={toogleShowModal}
                        userEmail={userBaseInformation?.userEmail}
                    />
            }
            {
                dialogMessage &&
                    <ActionConfirmationDialog
                        dialogData={dialogMessage}
                        showDialog={showDialog}
                        onDismiss={toggleHideDialog}
                    />
            }
        </>
    );
}