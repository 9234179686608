import { IMsalContext } from "@azure/msal-react";
import { AppInsightsLogger } from "../Utilities/AppInsightsLogger";
import {IAuthService} from "../Services/IAuthService"

export class AuthService implements IAuthService{
    
    msalContext: IMsalContext;
    private logger!: AppInsightsLogger;

    constructor(msalContext: IMsalContext, logger: AppInsightsLogger) {
        this.msalContext = msalContext;
        this.logger = logger;
    }

    public GetAccountName() {
        const accounts = this.msalContext.accounts;
        return accounts[0] && accounts[0].name;
    }

    public async RequestAccessToken(authRequest: any): Promise<string> {
        this.logger.trackEvent("Requesting access token...");
        const accounts = this.msalContext.accounts;
        const msalInstance = this.msalContext.instance;
        const request = {
            ...authRequest,
            account: accounts[0],
            redirectUri: `${window.origin}/auth`
        };

        try {
            var authResult = await msalInstance.acquireTokenSilent(request);
            return authResult?.accessToken;
        } catch (error) {
            throw error as Error;
        }
    }
}