import { v4 as uuidv4 } from 'uuid';

export class GenericResponseError extends Error {
    path: string;
    code: number;
    correlationId: string;

    constructor(message = 'Something went wrong', path = '', code = -1) {
        super(message);
        this.path = path;
        this.code = code;
        this.correlationId = uuidv4();
    }
}