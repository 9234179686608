import { getScrollBarWidth } from '@coherence-design-system/controls';
import { IStyle, mergeStyleSets } from '@fluentui/react';
import { navCollapsedWidth } from '@m365-admin/nav';

export const mainStyles = {
    position: 'fixed',
    top: 50,
    bottom: 0,
    right: 0
};

let scrollBarWidth = 0;
let currentZoomLevel = 0;

const ADDITIONAL_SPACING_FOR_UPGRADE_BANNER = {
    COLLAPSED: 17,
    EXPANDED: 10
}

const calculcateScrollBarWidth = () => {
    [scrollBarWidth, currentZoomLevel] = getScrollBarWidth(scrollBarWidth, currentZoomLevel, window.devicePixelRatio);
    return scrollBarWidth;
}

// the first three 'main' classes below are also used to get the main landmark region to cover content area (and only content area)
export const _Styles = mergeStyleSets({
    mainCollapsed: {
        ...mainStyles,
        selectors: {
            '@media screen and (min-width: 1023px)': {
                left: navCollapsedWidth + calculcateScrollBarWidth() + ADDITIONAL_SPACING_FOR_UPGRADE_BANNER.COLLAPSED
            },
            '@media screen and (max-width: 1024px)': {
                left: navCollapsedWidth + calculcateScrollBarWidth()
            }
        }
    } as IStyle,
    mainExpand: {
        ...mainStyles,
        selectors: {
            '@media screen and (min-width: 1023px)': {
                left: 221 + calculcateScrollBarWidth() + ADDITIONAL_SPACING_FOR_UPGRADE_BANNER.EXPANDED
            },
            '@media screen and (max-width: 1024px)': {
                left: 0 + calculcateScrollBarWidth()
            }
        }
    } as IStyle,
    rootDiv: {
        paddingRight: '30px',
        paddingLeft: '10px'
    } as IStyle,
    pageHeader: {
        marginBottom: '10px'
    } as IStyle,
    spacerDiv: {
        height: '34px'
    } as IStyle,
    dividerLine: {
        width: '100%',
        height: '1px',
        backgroundColor: 'black',
        marginBottom: '20px'
    } as IStyle,
    rowGap: {
        height: '30px'
    } as IStyle,
    mainAreaStyle: {
        selectors: {
            '@media screen and (min-width: 1023px)': {
                paddingLeft: 10,
            },
            '@media screen and (max-width: 1024px)': {
                paddingLeft: 0,
                left: 0,
            }
        }
    } as IStyle,
});