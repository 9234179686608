import { CoherenceDataGridColumn } from "@coherence-design-system/controls"
import { LocationConstants } from "./Locations"
import { Organization } from "../DataModels/Organization"

const getLocationDisplayName = (org: Organization) => {
    var locationName = "";
    if (org.city) {
        locationName += `${org.city}, `;
    }

    if (org.state) {
        locationName += `${org.state}, `;
    }

    if (org.country) {
        locationName += `${LocationConstants.CountryRegionDisplayName[org.country]}`;
    }
    
    return locationName;
}

export const OrgManagementGridColumns: CoherenceDataGridColumn<Organization>[] = [
    {
        key: 'orgName',
        name: 'Organization',
        fieldName: 'name',
        type: 'string',
        isResizable: true
    },
    {
        key: 'orgLocation',
        name: 'Location',
        type: 'string',
        isResizable: true,
        getValue: getLocationDisplayName
    },
    {
        key: 'orgDomains',
        name: 'Domains',
        type: 'string',
        isResizable: true,
        isMultiline: true,
        minColumnWidth: 250,
        getValue: (x) => x.domains?.map(value => value.domainName).join('; ') ?? null
    },
    {
        key: 'orgRoleTypes',
        name: 'Role Types',
        type: 'string',
        isResizable: true,
        isMultiline: true,
        minColumnWidth: 250,
        getValue: (x) => x.roleTypes?.map(value => value.roleName).join('; ') ?? null
    }
]