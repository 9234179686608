export class SearchUserNotFoundError extends Error {
    constructor(message: string, correlationId?: string, requestId?: string) {
        super(message);

        this.correlationId = correlationId;
        this.requestId = requestId;

        Object.setPrototypeOf(this, SearchUserNotFoundError.prototype);
    }

    correlationId?: string;
    requestId?: string;
}