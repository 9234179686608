import { CoherenceDataGrid, CoherenceDataGridColumn, CoherenceModal } from '@coherence-design-system/controls';
import { DefaultButton, DetailsListLayoutMode } from '@fluentui/react';
import React from 'react';
import { AccessRequestResult } from '../DataModels/AccessRequestResult';
import { AccessRequestRoleResult } from '../DataModels/AccessRequestRoleResult';

const listConfig: CoherenceDataGridColumn<AccessRequestRoleResult>[] = [
  {
    key: 'column1',
    name: 'Role Name',
    fieldName: 'name',
    minColumnWidth: 150,
    maxWidth: 250,
    isResizable: true,
    type: 'text'
  },
  {
    key: 'column2',
    name: 'Request Status',
    fieldName: 'reason',
    minColumnWidth: 150,
    maxWidth: 200,
    isResizable: true,
    type: 'text',
    getValue: (x) => x.reason == 'Skipped'? 'Has Access or Pending Request' : x.reason,
  }
];

interface IRequestConfirmationModalProps {
    isOpen: boolean;
    requestResponse: AccessRequestResult | undefined;
    userEmail: string | undefined;
    onDismiss: () => void;
}

export function RequestConfirmationModal(props: IRequestConfirmationModalProps) {
    const rolesToDisplay: AccessRequestRoleResult[] = [];

    if (props.requestResponse) {
        if (props.requestResponse.submittedRoles) {
            rolesToDisplay.push(...props.requestResponse.submittedRoles);
        }

        if (props.requestResponse.notSubmittedRoles) {
            rolesToDisplay.push(...props.requestResponse.notSubmittedRoles);
        }
    }

    const _onRenderCollapsibleContents = (): React.ReactNode => {
        return (
          <div style={{ height: '400px', border: '1px dotted gray' }}>
            <span>This content will collapse</span>
          </div>
        );
    };

    const _renderRoleSubmissionResultList = (): React.ReactNode => {
        if (rolesToDisplay.length > 0) {
            return (
                <div style={{ margin: '10px' }}>
                  <CoherenceDataGrid
                    listConfig={listConfig}
                    data={rolesToDisplay}
                    setKey={'set'}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    isScrollable={true}
                  />
                </div>
            );
        }
    };

    return (
      <div>
        <CoherenceModal
          isOpen={props.isOpen}
          onDismiss={props.onDismiss}
          modalWidth={'small'}
          height={'responsive'}
          title={'Request Submission Result'}
          subtitle={ 
              rolesToDisplay.length > 0
              ? `Role assignments have been transmitted for user ${props.userEmail}. Please check the following list for the result of each role.`
              : `No role assignment request is submitted for user ${props.userEmail}. Please try again.`
            }
          onRenderCollapsibleHeaderContents={_onRenderCollapsibleContents}
          includeStickyAboveStyles={true}
          onRenderFooter={() => {
            return <DefaultButton text={'Close'} onClick={props.onDismiss} />;
          }}
        >
          { _renderRoleSubmissionResultList() }
        </CoherenceModal>
      </div>
    );
  }