import React, { Fragment, useEffect, useState } from 'react';
import {
    ScrollablePane,
    Stack,
    IStackProps,
    IStackStyles,
    IStackTokens,
    Spinner,
    SpinnerSize } from "@fluentui/react";
import { AccessRequestCard } from '../../Components/AccessRequestCard';

import { AccessRequestDto } from '../../DataModels/AccessRequestDto';
import { IAMService } from '../../Services/IAMService';
import { AccessRequestState } from '../../Constants/AccessRequestState';
import { NotificationListItem } from '@coherence-design-system/controls';
import { ActionConfirmationDialog } from '../../Components/ActionConfirmationDialog';
import { useBoolean } from '@fluentui/react-hooks';
import { IGenericDialogTemplate } from '../../DataModels/GenericDialogTemplate';
import { GenericResponseError } from '../../DataModels/Errors/GenericResponseError';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { IAppInsightsLogger } from '../../Utilities/IAppInsightsLogger';
import { AdminPortalFooter } from '../../AdminPortalFooter';

const stackTokens: IStackTokens = { childrenGap: 17 };
const tokens = {
    stackTokens: {
      childrenGap: 20,
    },
    spinnerStack: {
      childrenGap: 20,
    },
  };

const loadingIndicatorRowProps: IStackProps = { horizontal: false, verticalAlign: 'center' };
const spinnerStyle: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
};

interface IRequestPageProps {
    iamService: IAMService;
    logger: IAppInsightsLogger;
    addNotificationAction: (newNotification: NotificationListItem) => void;
}

export function PendingRequestsPage(props: IRequestPageProps) {
    const iamServiceClient = props.iamService;

    const [requestData, setRequestData] = useState<AccessRequestDto[]>();
    const [isLoadingRequests, setLoadingRequestsStatus] = useState(false);
    const [isRequestDataFresh, setDataFreshStatus] = useState(false);

    const [showDialog, {toggle: toggleHideDialog}] = useBoolean(true);
    const [dialogMessage, setDialogMessage] = useState<IGenericDialogTemplate>();

    const _onActionSucceeds = (request: AccessRequestDto, actionName: 'approve' | 'reject') => {
        setRequestData(requestData?.filter(element => element.requestId !== request.requestId))
        if (actionName === 'approve') {
            setDialogMessage({
                title: `Request Approved`,
                actionType: actionName,
                messageBody: `User ${request.email} has been granted access to role "${request.requestedRoleName}".`,
                isError: false
            });
        } else {
            setDialogMessage({
                title: `Request Rejected`,
                actionType: actionName,
                messageBody: `The request from user ${request.email} to role "${request.requestedRoleName}" has been rejected.`,
                isError: false
            });
        }
        
        toggleHideDialog();
    };

    const _onActionFails = (request: AccessRequestDto, actionName: 'approve' | 'reject', error: Error) => {
        var errorMessage = `Failed to ${actionName} the request ${request.requestId}.`;
        if (error instanceof GenericResponseError) {
            var responseError = error as GenericResponseError;
            errorMessage = `${errorMessage} ${responseError.message}. Error status code: ${responseError.code}. Correlation Id: ${responseError.correlationId}.`
        }
        else {
            errorMessage = `${errorMessage} ${error.message}. Request Id: ${request.requestId}.`
        }

        if (actionName === 'approve') {
            setDialogMessage({
                title: `Failed To Approve Request`,
                actionType: actionName,
                messageBody: errorMessage,
                isError: true
            });
        } else {
            setDialogMessage({
                title: `Failed To Reject Request`,
                actionType: actionName,
                messageBody: errorMessage,
                isError: true
            });
        }
        
        toggleHideDialog();
    }

    useEffect(() => {
        if (!isRequestDataFresh && !isLoadingRequests) {
            setLoadingRequestsStatus(true);
            iamServiceClient.getAccessRequests(AccessRequestState.PendingApproval)
                .then((accessRequestList: AccessRequestDto[]) => {
                    setRequestData(accessRequestList);
                    setDataFreshStatus(true);
                    setLoadingRequestsStatus(false);
                })
                .catch((error) => {
                    props.logger.trackException(error, SeverityLevel.Critical);
                });
        }
    },[isRequestDataFresh,isLoadingRequests]);

    return (
        <>
            <ScrollablePane>
                <h1 style={{textAlign: 'center'}} role='heading'>Partner Requests</h1>
                {
                    !isLoadingRequests && isRequestDataFresh &&
                        <Stack style={{justifyContent:'center',alignItems:'center',display:'flex', paddingBottom: 40 }} tokens={stackTokens}>
                            {
                                requestData?.map(element => {
                                    return <AccessRequestCard
                                                key={element.requestId}
                                                requestData={element}
                                                iamService={iamServiceClient}
                                                onActionSucceeds={_onActionSucceeds}
                                                onActionFails={_onActionFails}
                                                notificationHandler={props.addNotificationAction} />
                                })
                            }
                        </Stack>
                }
                {
                    !isLoadingRequests &&
                        <AdminPortalFooter />
                }
                {
                    isLoadingRequests &&
                        <Stack {...loadingIndicatorRowProps} tokens={tokens.spinnerStack}>
                            <div style={spinnerStyle}>
                                <Spinner size={SpinnerSize.large} label='Loading' />
                            </div>
                            <div style={{position: 'absolute', bottom: '10%', width: '100%'}}>
                                <AdminPortalFooter />
                            </div>
                        </Stack>
                }
            </ScrollablePane>
            {
                dialogMessage && 
                    <ActionConfirmationDialog
                        dialogData={dialogMessage}
                        showDialog={showDialog}
                        onDismiss={toggleHideDialog}
                    />
            }
        </>
    );
}