import { ErrorStateIconType, IErrorStateComponentProps } from "@coherence-design-system/controls";
import { GenericResponseError } from "./Errors/GenericResponseError";

export class ErrorScreenTemplate {
    public static userNotFound: IErrorStateComponentProps = {
        headingText: "User Not Exist",
        descriptionText: `The user does not have access to Supply Chain Services.`,
        descriptionLinkText: 'Click here to submit an access request for this user.',
        descriptionLinkUrl: 'https://portal.supplychain.microsoft.com/requestaccess',
        iconType: ErrorStateIconType.OrangeCapAndCat
    };

    public static getNetworkError(error: Error): IErrorStateComponentProps {

        const errorProps: IErrorStateComponentProps = {
            headingText: "Failed to perform this action",
            descriptionLinkText: 'Reach out to Supply Chain Portal team for assistance.',
            descriptionLinkUrl: 'mailto:partnerportalengg@microsoft.com',
            iconType: ErrorStateIconType.InternetIssue
        };

        if (error instanceof GenericResponseError) {
            errorProps.descriptionText = `Network status: ${error.code}. Error message: ${error.message}. Correlation Id: ${error.correlationId}`;
        } else {
            errorProps.descriptionText = `Unknown error: ${error.message}. Stack trace: ${error.stack}`;
        }
        
        return errorProps;
    };
}