export class LocationConstants {
    public static readonly CountryRegionCode: string[] = [
        "AD",
        "AE",
        "AF",
        "AG",
        "AI",
        "AL",
        "AM",
        "AO",
        "AQ",
        "AR",
        "AS",
        "AT",
        "AU",
        "AW",
        "AX",
        "AZ",
        "BA",
        "BB",
        "BD",
        "BE",
        "BF",
        "BG",
        "BH",
        "BI",
        "BJ",
        "BL",
        "BM",
        "BN",
        "BO",
        "BQ",
        "BR",
        "BS",
        "BT",
        "BV",
        "BW",
        "BY",
        "BZ",
        "CA",
        "CC",
        "CD",
        "CF",
        "CG",
        "CH",
        "CI",
        "CK",
        "CL",
        "CM",
        "CN",
        "CO",
        "CR",
        "CV",
        "CW",
        "CX",
        "CY",
        "CZ",
        "DE",
        "DJ",
        "DK",
        "DM",
        "DO",
        "DZ",
        "EC",
        "EE",
        "EG",
        "ER",
        "ES",
        "ET",
        "FI",
        "FJ",
        "FK",
        "FM",
        "FO",
        "FR",
        "GA",
        "GB",
        "GD",
        "GE",
        "GG",
        "GF",
        "GH",
        "GI",
        "GL",
        "GM",
        "GN",
        "GP",
        "GQ",
        "GR",
        "GS",
        "GT",
        "GU",
        "GW",
        "GY",
        "HK",
        "HM",
        "HN",
        "HR",
        "HT",
        "HU",
        "ID",
        "IE",
        "IL",
        "IM",
        "IN",
        "IO",
        "IQ",
        "IS",
        "IT",
        "JE",
        "JM",
        "JO",
        "JP",
        "KE",
        "KG",
        "KH",
        "KI",
        "KM",
        "KN",
        "KR",
        "KW",
        "KY",
        "KZ",
        "LA",
        "LB",
        "LC",
        "LI",
        "LK",
        "LR",
        "LS",
        "LT",
        "LU",
        "LV",
        "LY",
        "MA",
        "MC",
        "MD",
        "ME",
        "MF",
        "MG",
        "MH",
        "MK",
        "ML",
        "MM",
        "MN",
        "MO",
        "MP",
        "MQ",
        "MR",
        "MS",
        "MT",
        "MU",
        "MV",
        "MW",
        "MX",
        "MY",
        "MZ",
        "NA",
        "NC",
        "NE",
        "NF",
        "NG",
        "NI",
        "NL",
        "NO",
        "NP",
        "NR",
        "NU",
        "NZ",
        "OM",
        "PA",
        "PE",
        "PF",
        "PG",
        "PH",
        "PK",
        "PL",
        "PM",
        "PN",
        "PR",
        "PS",
        "PT",
        "PW",
        "PY",
        "QA",
        "RE",
        "RO",
        "RS",
        "RU",
        "RW",
        "SA",
        "SB",
        "SC",
        "SE",
        "SG",
        "SH",
        "SI",
        "SJ",
        "SK",
        "SL",
        "SM",
        "SN",
        "SO",
        "SR",
        "SS",
        "ST",
        "SV",
        "SX",
        "SZ",
        "TC",
        "TD",
        "TF",
        "TG",
        "TH",
        "TJ",
        "TK",
        "TL",
        "TM",
        "TN",
        "TO",
        "TR",
        "TT",
        "TV",
        "TW",
        "TZ",
        "UA",
        "UG",
        "UM",
        "US",
        "UY",
        "UZ",
        "VA",
        "VC",
        "VE",
        "VG",
        "VI",
        "VN",
        "VU",
        "WF",
        "WS",
        "XK",
        "YE",
        "YT",
        "ZA",
        "ZM",
        "ZW",
    ];

    public static readonly CountryRegionDisplayName: {[countryCode: string]: string} = {
        RE: "Réunion",
        RO: "Romania",
        RS: "Serbia",
        RU: "Russia",
        RW: "Rwanda",
        QA: "Qatar",
        VE: "Venezuela",
        VG: "British Virgin Islands",
        VA: "Vatican City",
        VC: "Saint Vincent and the Grenadines",
        VN: "Vietnam",
        VI: "U.S. Virgin Islands",
        VU: "Vanuatu",
        SA: "Saudi Arabia",
        SC: "Seychelles",
        SB: "Solomon Islands",
        SE: "Sweden",
        SG: "Singapore",
        SI: "Slovenia",
        SH: "St Helena, Ascension, Tristan da Cunha",
        SK: "Slovakia",
        SJ: "Svalbard and Jan Mayen",
        SM: "San Marino",
        SL: "Sierra Leone",
        SO: "Somalia",
        SN: "Senegal",
        SS: "South Sudan",
        SR: "Suriname",
        ST: "São Tomé and Príncipe",
        SV: "El Salvador",
        SX: "Sint Maarten",
        SZ: "Swaziland",
        PA: "Panama",
        PF: "French Polynesia",
        PG: "Papua New Guinea",
        PE: "Peru",
        PK: "Pakistan",
        PH: "Philippines",
        PN: "Pitcairn Islands",
        PL: "Poland",
        PM: "Saint Pierre and Miquelon",
        PR: "Puerto Rico",
        PS: "Palestinian Authority",
        PW: "Palau",
        PT: "Portugal",
        PY: "Paraguay",
        UG: "Uganda",
        UA: "Ukraine",
        UM: "U.S. Outlying Islands",
        US: "United States",
        UZ: "Uzbekistan",
        UY: "Uruguay",
        ZM: "Zambia",
        ZA: "South Africa",
        ZW: "Zimbabwe",
        WF: "Wallis and Futuna",
        WS: "Samoa",
        TF: "French Southern Territories",
        TG: "Togo",
        TD: "Chad",
        TC: "Turks and Caicos Islands",
        TN: "Tunisia",
        TO: "Tonga",
        TL: "Timor-Leste",
        TM: "Turkmenistan",
        TJ: "Tajikistan",
        TK: "Tokelau",
        TH: "Thailand",
        TV: "Tuvalu",
        TW: "Taiwan",
        TT: "Trinidad and Tobago",
        TR: "Turkey",
        TZ: "Tanzania",
        YE: "Yemen",
        YT: "Mayotte",
        XJ: "Jan Mayen",
        XK: "Kosovo",
        XE: "Sint Eustatius",
        XS: "Saba",
        BQ: "Bonaire, Sint Eustatius and Saba",
        BR: "Brazil",
        BS: "Bahamas",
        BT: "Bhutan",
        BV: "Bouvet Island",
        BW: "Botswana",
        BY: "Belarus",
        BZ: "Belize",
        BA: "Bosnia and Herzegovina",
        BB: "Barbados",
        BD: "Bangladesh",
        BE: "Belgium",
        BF: "Burkina Faso",
        BG: "Bulgaria",
        BH: "Bahrain",
        BI: "Burundi",
        BJ: "Benin",
        BL: "Saint Barthélemy",
        BM: "Bermuda",
        BN: "Brunei",
        BO: "Bolivia",
        AS: "American Samoa",
        AR: "Argentina",
        AQ: "Antarctica",
        AW: "Aruba",
        AU: "Australia",
        AT: "Austria",
        AZ: "Azerbaijan",
        AX: "Åland Islands",
        AG: "Antigua and Barbuda",
        AF: "Afghanistan",
        AE: "United Arab Emirates",
        AD: "Andorra",
        AI: "Anguilla",
        AO: "Angola",
        AM: "Armenia",
        AL: "Albania",
        FR: "France",
        FM: "Micronesia",
        FO: "Faroe Islands",
        FI: "Finland",
        FJ: "Fiji",
        FK: "Falkland Islands",
        CR: "Costa Rica",
        CW: "Curaçao",
        CV: "Cabo Verde",
        CY: "Cyprus",
        CX: "Christmas Island",
        CZ: "Czech Republic",
        CA: "Canada",
        CC: "Cocos (Keeling) Islands",
        CD: "Congo (DRC)",
        CG: "Congo",
        CF: "Central African Republic",
        CI: "Côte d’Ivoire",
        CH: "Switzerland",
        CK: "Cook Islands",
        CM: "Cameroon",
        CL: "Chile",
        CO: "Colombia",
        CN: "China",
        ET: "Ethiopia",
        ES: "Spain",
        ER: "Eritrea",
        EG: "Egypt",
        EE: "Estonia",
        EC: "Ecuador",
        JP: "Japan",
        JM: "Jamaica",
        JO: "Jordan",
        JE: "Jersey",
        GU: "Guam",
        GT: "Guatemala",
        GW: "Guinea-Bissau",
        GQ: "Equatorial Guinea",
        GP: "Guadeloupe",
        GS: "South Georgia and South Sandwich Islands",
        GR: "Greece",
        GY: "Guyana",
        GE: "Georgia",
        GD: "Grenada",
        GG: "Guernsey",
        GF: "French Guiana",
        GA: "Gabon",
        GB: "United Kingdom",
        GM: "Gambia",
        GL: "Greenland",
        GN: "Guinea",
        GI: "Gibraltar",
        GH: "Ghana",
        DZ: "Algeria",
        DE: "Germany",
        DO: "Dominican Republic",
        DM: "Dominica",
        DJ: "Djibouti",
        DK: "Denmark",
        IS: "Iceland",
        IQ: "Iraq",
        IT: "Italy",
        IO: "British Indian Ocean Territory",
        IN: "India",
        IM: "Isle of Man",
        IL: "Israel",
        IE: "Ireland",
        ID: "Indonesia",
        NZ: "New Zealand",
        NU: "Niue",
        NP: "Nepal",
        NR: "Nauru",
        NL: "Netherlands",
        NO: "Norway",
        NI: "Nicaragua",
        NE: "Niger",
        NF: "Norfolk Island",
        NG: "Nigeria",
        NA: "Namibia",
        NC: "New Caledonia",
        KY: "Cayman Islands",
        KZ: "Kazakhstan",
        KR: "Korea",
        KW: "Kuwait",
        KI: "Kiribati",
        KH: "Cambodia",
        KM: "Comoros",
        KN: "Saint Kitts and Nevis",
        KE: "Kenya",
        KG: "Kyrgyzstan",
        HR: "Croatia",
        HT: "Haiti",
        HU: "Hungary",
        HK: "Hong Kong SAR",
        HN: "Honduras",
        HM: "Heard Island and McDonald Islands",
        MZ: "Mozambique",
        MY: "Malaysia",
        MX: "Mexico",
        MW: "Malawi",
        MV: "Maldives",
        MU: "Mauritius",
        MT: "Malta",
        MS: "Montserrat",
        MR: "Mauritania",
        MQ: "Martinique",
        MP: "Northern Mariana Islands",
        MO: "Macao SAR",
        MN: "Mongolia",
        MM: "Myanmar",
        ML: "Mali",
        MK: "North Macedonia",
        MH: "Marshall Islands",
        MG: "Madagascar",
        MF: "Saint Martin",
        ME: "Montenegro",
        MD: "Moldova",
        MC: "Monaco",
        MA: "Morocco",
        OM: "Oman",
        LY: "Libya",
        LV: "Latvia",
        LT: "Lithuania",
        LU: "Luxembourg",
        LR: "Liberia",
        LS: "Lesotho",
        LK: "Sri Lanka",
        LI: "Liechtenstein",
        LB: "Lebanon",
        LC: "Saint Lucia",
        LA: "Laos",
  };
}