import { CoherenceDataGridColumn } from "@coherence-design-system/controls";

export class Role {
    id?: string;
    name?: string;
    type?: string;
    isAssigned?: boolean;
}

export const columns: CoherenceDataGridColumn<Role>[] =
    [
        {
            key: 'RoleName',
            name: 'Role Name',
            fieldName: 'name',
            type: 'string',
            // minColumnWidth: 200,
            // maxWidth: 300,
            isResizable: true
        },
        {
            key: 'RoleType',
            name: 'Type',
            fieldName: 'type',
            type: 'string',
            // minColumnWidth: 80,
            // maxWidth: 100,
            isResizable: true
        },
        {
            key: 'isUserAssignedTo',
            name: 'Assignment Status',
            type: 'string',
            isResizable: true,
            // minColumnWidth: 130,
            // maxWidth: 130,
            getValue: (x) => x.isAssigned? 'Granted' : '',
        }
    ];