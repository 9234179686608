import { CoherenceHeader, CoherencePanelSize, NotificationListItem } from '@coherence-design-system/controls';
import React, { useState } from 'react';
import { Link } from '@fluentui/react';
import { IUserProfile } from './DataModels/IUserProfile';
import { siteTitle } from './Constants/SiteConstants';

export interface IAdminPortalHeaderProps {
    userProfile: IUserProfile | undefined;
    userProfilePhotoLink: string | undefined;
    notificationItems: NotificationListItem[];
    logoutAction: () => void;
}

export function AdminPortalHeader(props: IAdminPortalHeaderProps) {
    const notificationItems = props.notificationItems;
    const [newNotificationCount, setNewNotificationCount] = useState(props.notificationItems.length);

    const _onDismiss = (id: string[]): void => {

    };

    const _userProfilePanelBody = <>{ 'For issues regarding your account, please reach out to ' }<Link href='mailto:partnerportalengg@microsoft.com'>our engineering team</Link>{' for help.'}</>

    const _updateItem = (
        itemKey: string,
        dStatus: NotificationListItem['displayStatus'],
        rStatus?: NotificationListItem['status']
    ) => {
        notificationItems.map((item) => {
            if (item.itemKey === itemKey) {
                // Decrease notification badge counter by 1 if item is unread
                if (item.status === 'unread') {
                    setNewNotificationCount(newNotificationCount - 1);
                }

                item.displayStatus = dStatus;
                if (rStatus) {
                    item.status = rStatus;
                }
            }
        });
    };

    return (
        <CoherenceHeader
            headerLabel={'header'}
            appNameSettings={{
                label: siteTitle
            }}
            farRightSettings={{
                profileSettings: {
                    buttonSettings: {
                        title: 'Profile',
                        ariaLabel: 'Profile'
                    },
                    panelSettings: {
                        fullName: props.userProfile?.displayName,
                        emailAddress: props.userProfile?.mail,
                        imageUrl: props.userProfilePhotoLink,
                        logOutLink: '#',
                        onLogOut: props.logoutAction,
                        body: _userProfilePanelBody
                    }
                },
                notificationsSettings: {
                    buttonSettings: {
                        title: 'Notification',
                    },
                    panelSettings: {
                        titleText: 'Notifications',
                        isUndoDismissAllVisible: true,
                        items: notificationItems,
                        newNotifications: newNotificationCount,
                        onDismissMultiple: _onDismiss,
                        updateItem: _updateItem,
                        panelSize: CoherencePanelSize.medium,
                        backButton: {
                            text: 'back',
                            ariaLabel: 'back'
                        }
                    },
                }
            }}
        />
    );
}
