import React, { Fragment, useState } from 'react';
import { Dialog, PrimaryButton, DefaultButton, DialogFooter, DialogType } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { IGenericDialogTemplate } from '../DataModels/GenericDialogTemplate';

const dialogModalStyle = { main: { maxWidth: 450 } };

interface IActionConfirmationDialogProps {
    dialogData: IGenericDialogTemplate;
    showDialog: boolean;
    onDismiss: () => void;
}

export function ActionConfirmationDialog(props: IActionConfirmationDialogProps) {

    const dialogModalProps = {
        isBlocking: true,
        styles: dialogModalStyle,
    };

    const dialogContentProps = {
        type: DialogType.normal,
        title: props.dialogData.title,
        closeButtonAriaLabel: 'Close',
        subText: props.dialogData.messageBody,
    }

    return (
        <Dialog
            hidden={props.showDialog}
            onDismiss={props.onDismiss}
            dialogContentProps={dialogContentProps}
            modalProps={dialogModalProps}
        >
            <DialogFooter>
                <DefaultButton onClick={props.onDismiss} text="Close" />
            </DialogFooter>
        </Dialog>
    );
}