import { ComboBox, IComboBox, IComboBoxOption } from '@fluentui/react';
import React, { FormEvent } from 'react';
import { LocationConstants } from '../../Constants/Locations';

interface ICountryDropdownProps {
    id: string;
    onSelectCountry: (event: FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) => void;
    selectedKey: string;
    showErrorMessage: boolean;
}

export function CountryDropdown(props: ICountryDropdownProps) {

    const organizationOptions: IComboBoxOption[] = 
        LocationConstants
        .CountryRegionCode
        .map(countryCode => (
            {   
                key: countryCode,
                text: LocationConstants.CountryRegionDisplayName[countryCode]
            }))
        .sort((a, b) => a.text.localeCompare(b.text));
    
    return (
        <>
            <ComboBox
                id={props.id}
                label="Country/Region"
                data-testid="orgCountry_Combobox"
                onChange={props.onSelectCountry}
                selectedKey={props.selectedKey}
                placeholder="Select the country or region where the organization is located"
                options={organizationOptions}
                required={true}
                errorMessage={props.showErrorMessage && props.selectedKey == ''? "Country/region is required." : undefined}
            />
        </>
    );
} 