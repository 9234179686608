import { backendEndpoint, loginRequest } from '../Configs/AuthConfigs';
import { AccessRequestState } from '../Constants/AccessRequestState';
import { AccessRequestDto } from '../DataModels/AccessRequestDto';
import { RequestActionDto } from '../DataModels/RequestActionDto';
import { RoleAssignmentDto } from '../DataModels/RoleAssignmentDto';
import { SearchUserResult } from '../DataModels/SearchUserResult';
import { Role } from '../DataModels/Role';
import { SearchUserNotFoundError } from '../DataModels/Errors/SearchUserNotFoundError';
import { GenericResponseError } from '../DataModels/Errors/GenericResponseError';
import { AccessTokenError } from '../DataModels/Errors/AccessTokenError';
import { AppInsightsLogger } from '../Utilities/AppInsightsLogger';
import { IAuthService } from './IAuthService';
import { IAppInsightsLogger } from '../Utilities/IAppInsightsLogger';
import { CreateUserDto } from '../DataModels/CreateUserDto';
import { Organization } from '../DataModels/Organization';
import { OrganizationOnboardingRequest } from '../DataModels/OrganizationOnboardingRequest';
import { IOrganizationOnboardingResult } from '../DataModels/OrganizationOnboardingResult';
import { OrganizationDomainCheckResponse } from '../DataModels/OrganizationDomainCheckResponse';

export class IAMService {
    private authService!: IAuthService;
    private logger!: IAppInsightsLogger;

    constructor(authService: IAuthService, logger: IAppInsightsLogger) {
        this.authService = authService;
        this.logger = logger;
    }

    public async getAccessRequests(requestState: AccessRequestState): Promise<AccessRequestDto[]> {
        let accessToken = await this.authService.RequestAccessToken(loginRequest);
        if (!accessToken) {
            throw new AccessTokenError();
        }

        this.logger.trackEvent(`IAMService - Fetching all access requests with state ${requestState.toString()}.`);

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);

        const options = {
            method: "GET",
            headers: headers
        };

        let response = await fetch(`${backendEndpoint}/access-request?status=${requestState.toString()}`, options)
        if (response.ok) {
            return response.json();
        }
        
        let responseError = new GenericResponseError(`Failed to get requests: ${response.statusText}`, response.url, response.status);
        throw responseError;
    }

    public async approveRequest(requestAction: RequestActionDto) {
        return await this.sendRequestAction('approve', requestAction);
    }

    public async rejectRequest(requestAction: RequestActionDto) {
        return await this.sendRequestAction('reject', requestAction);
    }

    private async sendRequestAction(operation: 'approve' | 'reject', requestAction: RequestActionDto) {
        let accessToken = await this.authService.RequestAccessToken(loginRequest);

        if (!accessToken) {
            throw new AccessTokenError();
        }

        this.logger.trackEvent(`IAMService - Performing review action "${operation}" on request ${requestAction.requestId}.`);

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);
        headers.append("Content-Type", "application/json");

        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(requestAction)
        };

        let response = await fetch(`${backendEndpoint}/access-request/${operation}`, options);
        if (!response.ok) {
            let responseError = new GenericResponseError(`Failed to ${operation} the request: ${response.statusText}`, response.url, response.status);
            throw responseError;
        }
    }

    public async searchUser(userEmail: string): Promise<SearchUserResult> {
        let accessToken = await this.authService.RequestAccessToken(loginRequest);

        if (!accessToken) {
            throw new AccessTokenError();
        }

        this.logger.trackEvent(`IAMService - Searching user.`);

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);

        const options = {
            method: "GET",
            headers: headers,
        };

        let response = await fetch(`${backendEndpoint}/search-user?userEmail=${userEmail}`, options);
        if (response.ok) {
            return response.json();
        }

        if (response.status == 404 || response.status == 400) {
            let searchUserError = await response.json() as SearchUserNotFoundError;
            throw new SearchUserNotFoundError(searchUserError.message, searchUserError.correlationId, searchUserError.requestId);
        }
        
        let responseError = new GenericResponseError(`Failed to search user: ${response.statusText}`, response.url, response.status);
        throw responseError;
    }

    public async assignRolesToUser(userEmail: string, roleIds: string[]) {
        let accessToken = await this.authService.RequestAccessToken(loginRequest);

        if (!accessToken) {
            throw new AccessTokenError();
        }

        this.logger.trackEvent(`IAMService - Creating role assignment for user.`, {"roles": roleIds});

        let roleAssignments: RoleAssignmentDto = {
            UserEmail: userEmail,
            Roles: roleIds
        };

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);
        headers.append("Content-Type", "application/json");

        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(roleAssignments)
        };

        let response = await fetch(`${backendEndpoint}/role-assignment`, options);
        if (response.ok) {
            return response.json();
        }
        
        let responseError = new GenericResponseError(`Failed to create role assignment: ${response.statusText}`, response.url, response.status);
        throw responseError;
    }

    public async getRolesForUser(userId: string): Promise<Role[]> {
        let accessToken = await this.authService.RequestAccessToken(loginRequest);

        if (!accessToken) {
            throw new AccessTokenError();
        }

        this.logger.trackEvent(`IAMService - Fetching all roles for user.`);

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);

        const options = {
            method: "GET",
            headers: headers,
        };
        
        let response = await fetch(`${backendEndpoint}/user-roles?userId=${userId}`, options);
        if (response.ok) {
            return response.json();
        }
            
        let responseError = new GenericResponseError(`Failed to fetch roles for user ${userId}: ${response.statusText}`, response.url, response.status);
        throw responseError;
    }

    public async getOrganizationsByDomain(domain?: string): Promise<OrganizationDomainCheckResponse> {
        let accessToken = await this.authService.RequestAccessToken(loginRequest);

        if (!accessToken) {
            throw new AccessTokenError();
        }

        this.logger.trackEvent(`IAMService - Fetching organizations for domain ${domain}.`);

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);

        const options = {
            method: "GET",
            headers: headers,
        };

        let response = await fetch(`${backendEndpoint}/organization/check-domain?domainName=${domain}`, options);
        if (response.ok) {
            console.log(JSON.stringify(await response.body));
            return response.json();
        }

        let responseError = new GenericResponseError(`Failed to fetch organizations with ${domain? domain : "any domain"}: ${response.statusText}`, response.url, response.status);
        throw responseError;
    }

    public async getOrganizations(): Promise<Organization[]> {
        let accessToken = await this.authService.RequestAccessToken(loginRequest);

        if (!accessToken) {
            throw new AccessTokenError();
        }

        this.logger.trackEvent(`IAMService - Fetching all organizations.`);

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);

        const options = {
            method: "GET",
            headers: headers,
        };

        let response = await fetch(`${backendEndpoint}/organization`, options);
        if (response.ok) {
            console.log(JSON.stringify(await response.body));
            return response.json();
        }

        let responseError = new GenericResponseError(`Failed to fetch all organizations list.}: ${response.statusText}`, response.url, response.status);
        throw responseError;
    }

    public async onboardOrganization(newOrgRequest: OrganizationOnboardingRequest): Promise<IOrganizationOnboardingResult> {
        let accessToken = await this.authService.RequestAccessToken(loginRequest);

        if (!accessToken) {
            throw new AccessTokenError();
        }

        this.logger.trackEvent(
            `IAMService - Onboard new organization.`, 
            {
                "Domain": newOrgRequest.domainName,
                "DisplayName": newOrgRequest.organizationName,
                "Country": newOrgRequest.country
            });

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);
        headers.append("Content-Type", "application/json");

        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(newOrgRequest)
        };

        let response = await fetch(`${backendEndpoint}/organization/onboard`, options);
        if (response.ok) {
            return response.json();
        }
        
        let responseError = new GenericResponseError(`Failed to create role assignment: ${response.statusText}`, response.url, response.status);
        throw responseError;
    }

    public async onboardUser(createuser : CreateUserDto) {
        let accessToken = await this.authService.RequestAccessToken(loginRequest);

        if (!accessToken) {
            throw new AccessTokenError();
        }

        this.logger.trackEvent(`IAMService - onboardUser.`);

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);
        headers.append("Content-Type", "application/json");

        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(createuser)
        };
        
        let response = await fetch(`${backendEndpoint}/user-onboard`, options);
        if (response.ok) {
            return response.json();
        }
            
        let responseError = new GenericResponseError(`Failed to add user organization: ${response.statusText}`, response.url, response.status);
        throw responseError;
    }
}